import { LatLonWithSpecificScore } from "../../api/scoreApi";
import { AsyncState } from "../../models/AsyncState";

export interface ScoreState extends AsyncState {
  selectedScore: LatLonWithSpecificScore | null;
  scores: LatLonWithSpecificScore[];
}

export const scoreInitialState: ScoreState = {
  selectedScore: null,
  scores: [],
  isLoading: false,
  error: null,
};
