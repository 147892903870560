import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchDemandFacilities,
  fetchDemandFacilitiesFailure,
  fetchDemandFacilitiesSuccess,
  resetDemandFacilities,
  resetIsExpandedDemand,
  setIsExpandedDemand,
} from "./demandFacilitiesSlice";
import { selectDemandFacilitiesIsExpanded } from "./demandFacilitiesSelectors";
import { BusinessType } from "../../models/BusinessType";
import { selectSelectedBusinessType } from "../businessType/businessTypeSelectors";
import { ScoreType } from "../../api/scoreTypeApi";
import { selectScoreTypes } from "../scoreType/scoreTypeSelectors";
import { Facility } from "../../models/Facility";
import { getFacilities, GetFacilitiesParams } from "../../api/facilityApi";
import { selectSelectedScore } from "../score/scoreSelectors";
import { LatLonWithSpecificScore } from "../../api/scoreApi";
import { ErrorResponse } from "../../utils/errorHandler";
import { setSelectedScore } from "../score/scoreSlice";

// Watchers
function* watchSetIsExpandedDemand() {
  yield takeLatest([setIsExpandedDemand.type, setSelectedScore.type], fetchDemandFacilitiesSaga);
}

function* watchResetDemand() {
  yield takeLatest(resetIsExpandedDemand.type, resetDemandFacilitiesSaga);
}

function* watchFetchDemandFacilities() {
  yield takeLatest(fetchDemandFacilities.type, handleFetchDemandFacilities);
}

// Sagas
function* fetchDemandFacilitiesSaga() {
  const isExpanded: boolean = yield select(selectDemandFacilitiesIsExpanded);
  const selectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
  const scoreTypes: ScoreType[] = yield select(selectScoreTypes);
  const selectedScore: LatLonWithSpecificScore | null = yield select(selectSelectedScore);
  const demandScoreType: ScoreType | undefined = scoreTypes.find((st) => st.name === "Demand");

  if (!isExpanded || !selectedBusinessType || !scoreTypes || !selectedScore || !demandScoreType) return;

  yield put(fetchDemandFacilities());
}

function* resetDemandFacilitiesSaga() {
  yield put(resetDemandFacilities());
}

function* handleFetchDemandFacilities() {
  try {
    const selectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
    const scoreTypes: ScoreType[] = yield select(selectScoreTypes);
    const selectedScore: LatLonWithSpecificScore | null = yield select(selectSelectedScore);
    const demandScoreType: ScoreType | undefined = scoreTypes.find((st) => st.name === "Demand");

    if (!selectedBusinessType || !selectedScore || !demandScoreType) {
      yield put(
        fetchDemandFacilitiesFailure({
          message: "Please ensure that the business type and score are selected and Score types include demand.",
        })
      );
      return;
    }

    const params: GetFacilitiesParams = {
      business_type_id: selectedBusinessType.id,
      lat_lon_id: selectedScore.id,
      score_type_id: demandScoreType.id,
    };

    const facilities: Facility[] = yield call(getFacilities, params);
    yield put(fetchDemandFacilitiesSuccess(facilities));
  } catch (error) {
    yield put(fetchDemandFacilitiesFailure(error as ErrorResponse));
  }
}

// Root saga
export function* demandFacilitiesSaga() {
  yield all([fork(watchSetIsExpandedDemand), fork(watchResetDemand), fork(watchFetchDemandFacilities)]);
}
