import { AsyncState } from "../../models/AsyncState";
import { CustomBusiness } from "../../models/CustomBusiness";

export interface CustomBusinessState extends AsyncState {
  selectedCustomBusiness: CustomBusiness | null;
  customBusinesses: CustomBusiness[];
}

export const initialCustomBusinessState: CustomBusinessState = {
  selectedCustomBusiness: null,
  customBusinesses: [],
  isLoading: false,
  error: null,
};
