import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialAccessibilityFacilitiesState } from "./accessibilityFacilitiesState";
import { ErrorResponse } from "../../utils/errorHandler";
import { Facility } from "../../models/Facility";

const accessibilityFacilitiesSlice = createSlice({
  name: "accessibilityFacilities",
  initialState: initialAccessibilityFacilitiesState,
  reducers: {
    setIsExpandedAccessibility(state) {
      state.isExpanded = true;
    },
    resetIsExpandedAccessibility(state) {
      state.isExpanded = false;
    },
    fetchAccessibilityFacilities(state) {
      state.error = null;
      state.isLoading = true;
    },
    fetchAccessibilityFacilitiesSuccess(state, action: PayloadAction<Facility[]>) {
      state.facilities = action.payload;
      state.isLoading = false;
    },
    fetchAccessibilityFacilitiesFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetAccessibilityFacilities(state) {
      state.error = initialAccessibilityFacilitiesState.error;
      state.isLoading = initialAccessibilityFacilitiesState.isLoading;
      state.facilities = initialAccessibilityFacilitiesState.facilities;
    },
  },
});

export const {
  setIsExpandedAccessibility,
  resetIsExpandedAccessibility,
  fetchAccessibilityFacilities,
  fetchAccessibilityFacilitiesSuccess,
  fetchAccessibilityFacilitiesFailure,
  resetAccessibilityFacilities,
} = accessibilityFacilitiesSlice.actions;

export const accessibilityFacilitiesReducer = accessibilityFacilitiesSlice.reducer;
