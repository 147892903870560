import axiosInstance from "./axios";

export type CitySummary = {
  id: number;
  name: string;
  lat: number;
  lon: number;
};

export type GetCitySummariesParams = {
  region_ids: number[];
  business_type_id: number;
  score_type_id: number;
};

export const getCitySummaries = async (params: GetCitySummariesParams): Promise<CitySummary[]> => {
  const formattedParams = {
    ...params,
    region_ids: params.region_ids.join(","),
  };
  const { data } = await axiosInstance.get<CitySummary[]>("/city/cities/city_summaries/", { params: formattedParams });
  return data;
};
