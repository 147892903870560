import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { coordinateToLatLng } from "../../utils/mapUtils";

export const selectGoogleMapState = (state: RootState) => state.googleMap;

export const selectGoogleMapCenter = createSelector(selectGoogleMapState, (googleMap) => googleMap.center);
export const selectGoogleMapZoom = createSelector(selectGoogleMapState, (googleMap) => googleMap.zoom);
export const selectGoogleMapBounds = createSelector(selectGoogleMapState, (googleMap) => googleMap.bounds);
export const selectGoogleMapDimensions = createSelector(selectGoogleMapState, (googleMap) => googleMap.dimensions);

// Computed Selectors
export const selectGoogleMapCenterLatLon = createSelector(selectGoogleMapCenter, (center) => (center ? coordinateToLatLng(center) : null));
export const selectGoogleMapLatLngBounds = createSelector(selectGoogleMapBounds, (bounds) =>
  bounds ? new google.maps.LatLngBounds({ lat: bounds.south, lng: bounds.west }, { lat: bounds.north, lng: bounds.east }) : null
);
