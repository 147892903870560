import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { CustomBusinessValue } from "../../models/CustomBusinessValue";
import { normalizeInteger, parseNumber } from "../../utils/numberUtils";
import { arrayToIdObject } from "../../utils/arrayObjectConversions";
import { Coordinate } from "../../models/Coordinate";
import { CUSTOM_BUSINESS_MARKER_MAX_SIZE, CUSTOM_BUSINESS_MARKER_MIN_SIZE } from "../../config/constants";

export const selectCustomBusinessValueState = (state: RootState) => state.customBusinessValue;

export const selectSelectedCustomBusinessValue = createSelector(selectCustomBusinessValueState, (state) => state.selectedCustomBusinessValue);
export const selectCustomBusinessValues = createSelector(selectCustomBusinessValueState, (state): CustomBusinessValue<string>[] => state.customBusinessValues);
export const selectCustomBusinessValuesLoading = createSelector(selectCustomBusinessValueState, (state) => state.isLoading);
export const selectCustomBusinessValuesError = createSelector(selectCustomBusinessValueState, (state) => state.error);

// Computed selectors
export const selectCustomBusinessValueObjects = createSelector([selectCustomBusinessValues], arrayToIdObject);
export interface CustomBusinessValueMarker extends CustomBusinessValue<number> {
  markerSize: number;
}

export const selectCustomBusinessValueMarkers = createSelector([selectCustomBusinessValues], (valueObjects): CustomBusinessValueMarker[] => {
  let valueMarkerObject: CustomBusinessValueMarker[] = [];

  const parsedValues: CustomBusinessValueMarker[] = [];
  const markerSizes: number[] = [];

  for (const valueObj of valueObjects) {
    let parsedValue: number;
    if (typeof valueObj.value === "number") {
      parsedValue = valueObj.value;
    } else if (typeof valueObj.value === "string") {
      const parsed = parseNumber(valueObj.value);
      if (parsed === null) {
        return [];
      }
      parsedValue = parsed;
    } else {
      return [];
    }

    markerSizes.push(parsedValue);
    parsedValues.push({
      ...valueObj,
      value: parsedValue,
      markerSize: parsedValue,
    });
  }

  if (parsedValues.length === 0) {
    return [];
  }

  const originalMin = Math.min(...markerSizes);
  const originalMax = Math.max(...markerSizes);

  const normalizedValues: CustomBusinessValueMarker[] = parsedValues.map<CustomBusinessValueMarker>((valueObj) => ({
    ...valueObj,
    markerSize: normalizeInteger({
      value: valueObj.markerSize,
      originalMin,
      originalMax,
      newMin: CUSTOM_BUSINESS_MARKER_MIN_SIZE,
      newMax: CUSTOM_BUSINESS_MARKER_MAX_SIZE,
      // TODO: validate that ! doesn't cause issues
    })!,
  }));

  valueMarkerObject = normalizedValues.sort((a, b) => b.markerSize - a.markerSize);

  return valueMarkerObject;
});

export const selectCustomBusinessValueMarkersCoordinates = createSelector([selectCustomBusinessValueMarkers], (valueMarkers): Coordinate[] => {
  return valueMarkers.map(({ lat, lon }) => ({ latitude: lat, longitude: lon }));
});
