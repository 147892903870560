import { AsyncState } from "../../models/AsyncState";
import { Facility } from "../../models/Facility";

export interface SupplyFacilitiesState extends AsyncState {
  isExpanded: boolean;
  facilities: Facility[];
}

export const initialSupplyFacilitiesState: SupplyFacilitiesState = {
  isExpanded: false,
  facilities: [],
  isLoading: false,
  error: null,
};
