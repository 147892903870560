import { AsyncState } from "../../models/AsyncState";
import { CustomBusinessProperty } from "../../models/CustomBusinessProperty";
import { CustomBusinessUploadedFile } from "../../models/CustomBusinessUploadedFile";

export interface CustomBusinessUploadedFilesState extends AsyncState {
  selectedUploadedFile: CustomBusinessUploadedFile | null;
  selectedUploadedFileProperty: CustomBusinessProperty | null;
  uploadedFiles: CustomBusinessUploadedFile[];
}

export const initialCustomBusinessUploadedFilesState: CustomBusinessUploadedFilesState = {
  selectedUploadedFile: null,
  selectedUploadedFileProperty: null,
  uploadedFiles: [],
  isLoading: false,
  error: null,
};
