import { RegionSummary } from "../api/regionApi";
import { LatLonWithSpecificScore } from "../api/scoreApi";
import { CALCULATION_MAX_ZOOM, COUNTRY_ZOOM, ONE_REGION_ZOOM } from "../config/constants";
import { Coordinate } from "../models/Coordinate";
import { CountrySummary } from "../models/CountrySummary";
import { Dimensions } from "../models/Dimensions";

export const calculateCountryCenterZoom = (country: CountrySummary): { center: Coordinate; zoom: number } => ({
  center: { latitude: country.lat, longitude: country.lon },
  zoom: COUNTRY_ZOOM,
});

export const calculateBoundsForRegions = (regions: RegionSummary[]): google.maps.LatLngBounds => {
  return calculateBounds(regions.map((region) => ({ latitude: region.lat, longitude: region.lon })));
};

export const calculateBoundsForScores = (scores: LatLonWithSpecificScore[]): google.maps.LatLngBounds => {
  return calculateBounds(scores.map((score) => ({ latitude: score.coordinate.latitude, longitude: score.coordinate.longitude })));
};

export const calculateRegionsCenterZoom = (regions: RegionSummary[], mapDimensions: Dimensions): { center: Coordinate; zoom: number } => {
  if (regions.length === 1) {
    return { center: { latitude: regions[0].lat, longitude: regions[0].lon }, zoom: ONE_REGION_ZOOM };
  }

  const center = calculateCenter(regions.map((region) => ({ latitude: region.lat, longitude: region.lon })));
  const bounds = calculateBoundsForRegions(regions);
  const zoom = calculateZoomForBounds(bounds, COUNTRY_ZOOM, mapDimensions);
  return { center, zoom };
};

export const calculateScoresCenterZoom = (scores: LatLonWithSpecificScore[], mapDimensions: Dimensions): { center: Coordinate; zoom: number } => {
  const center = calculateCenter(scores.map((score) => ({ latitude: score.coordinate.latitude, longitude: score.coordinate.longitude })));
  const bounds = calculateBoundsForScores(scores);
  const zoom = calculateZoomForBounds(bounds, COUNTRY_ZOOM, mapDimensions);
  return { center, zoom };
};

export const calculateCoordnatesCenterZoom = (coordinates: Coordinate[], mapDimensions: Dimensions): { center: Coordinate; zoom: number } => {
  const center = calculateCenter(coordinates);
  const bounds = calculateBounds(coordinates);
  const zoom = calculateZoomForBounds(bounds, COUNTRY_ZOOM, mapDimensions);
  return { center, zoom };
};

export const calculateBounds = (coordinates: Coordinate[]): google.maps.LatLngBounds => {
  const bounds = new google.maps.LatLngBounds();
  coordinates.forEach((location) => {
    bounds.extend({ lat: location.latitude, lng: location.longitude });
  });
  return bounds;
};

const calculateCenter = (coordinates: Coordinate[]): Coordinate => {
  const latSum = coordinates.reduce((sum, coord) => sum + coord.latitude, 0);
  const lonSum = coordinates.reduce((sum, coord) => sum + coord.longitude, 0);
  const count = coordinates.length;

  return { latitude: latSum / count, longitude: lonSum / count };
};

export const coordinateToLatLng = (coordinate: Coordinate): google.maps.LatLng => {
  return new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
};

export const calculateZoomForBounds = (bounds: google.maps.LatLngBounds, minZoom: number, mapDimensions: Dimensions): number => {
  const LATITUDE_CONSTANT = 360 / mapDimensions.height;

  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();

  const latSpan = northEast.lat() - southWest.lat();
  const lngSpan = northEast.lng() - southWest.lng();

  const mapHeight = mapDimensions.height;
  const mapWidth = mapDimensions.width;

  const latZoom = Math.floor(Math.log2(mapHeight / LATITUDE_CONSTANT / latSpan));
  const lngZoom = Math.floor(Math.log2(mapWidth / LATITUDE_CONSTANT / lngSpan));

  const zoom = Math.min(latZoom, lngZoom, CALCULATION_MAX_ZOOM);
  return Math.max(zoom, minZoom);
};
