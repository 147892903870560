import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { averageScoresAsyncStateInitialState } from "./citiesAverageScoreState";
import { ErrorResponse } from "../../utils/errorHandler";
import { Scores } from "../../models/Scores";

export const citiesAverageScoreSlice = createSlice({
  name: "citiesAverageScore",
  initialState: averageScoresAsyncStateInitialState,
  reducers: {
    setCityAveragesTabIsOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpened = payload;
    },
    fetchAverageScores: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchAverageScoresSuccess: (state, { payload }: PayloadAction<Scores>) => {
      state.isLoading = false;
      state.averageScores = payload;
    },
    fetchAverageScoresFailure: (state, { payload }: PayloadAction<ErrorResponse>) => {
      state.isLoading = false;
      state.error = payload;
    },
    resetAverageScores: (state) => {
      state.averageScores = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { setCityAveragesTabIsOpened, fetchAverageScores, fetchAverageScoresSuccess, fetchAverageScoresFailure, resetAverageScores } =
  citiesAverageScoreSlice.actions;

export const citiesAverageScoreReducer = citiesAverageScoreSlice.reducer;
