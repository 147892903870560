import { all, fork } from "redux-saga/effects";
import { countrySagas } from "./country/countrySaga";
import { businessTypeSaga } from "./businessType/businessTypeSaga";
import { scoreTypeSagas } from "./scoreType/scoreTypeSaga";
import { regionSaga } from "./region/regionSaga";
import { citySaga } from "./city/citySaga";
import { scoreSaga } from "./score/scoreSaga";
import { citiesAverageScoreSaga } from "./citiesAverageScore/citiesAverageScoreSaga";
import { latLonSaga } from "./latLon/latLonSaga";
import { customBusinessUploadedFilesSaga } from "./customBusinessUploadedFiles/customBusinessUploadedFilesSaga";
import authSaga from "./auth/authSaga";
import { customBusinessValueSaga } from "./customBusinessValue/customBusinessValueSaga";
import { accessibilityFacilitiesSaga } from "./accessibilityFacilities/accessibilityFacilitiesSaga";
import { demandFacilitiesSaga } from "./demandFacilities/demandFacilitiesSaga";
import { supplyFacilitiesSaga } from "./supplyFacilities/supplyFacilitiesSaga";
import { googleMapSaga } from "./googleMap/googleMapSaga";
import { customBusinessUploadSaga } from "./customBusinessUpload/customBusinessUploadSaga";

export const rootSaga = function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(countrySagas),
    fork(citySaga),
    fork(regionSaga),
    fork(businessTypeSaga),
    fork(scoreTypeSagas),
    fork(scoreSaga),
    fork(citiesAverageScoreSaga),
    fork(latLonSaga),
    fork(customBusinessUploadSaga),
    fork(customBusinessUploadedFilesSaga),
    fork(customBusinessValueSaga),
    fork(demandFacilitiesSaga),
    fork(supplyFacilitiesSaga),
    fork(accessibilityFacilitiesSaga),
    fork(googleMapSaga),
  ]);
};
