import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { selectSelectedRegionIds, selectSelectedRegions } from "../region/regionSelectors";
import { selectSelectedBusinessTypeId } from "../businessType/businessTypeSelectors";
import { selectSelectedScoreTypeId } from "../scoreType/scoreTypeSelectors";
import { fetchCities, fetchCitiesFailure, fetchCitiesSuccess, setSelectedCities } from "./citySlice";
import { CitySummary, getCitySummaries, GetCitySummariesParams } from "../../api/cityApi";
import { ErrorResponse } from "../../utils/errorHandler";
import { selectCities, selectIsEnabledCitiesDropdown, selectSelectedCities } from "./citySelectors";
import { setSelectedRegions } from "../region/regionSlice";
import { setSelectedBusinessType } from "../businessType/businessTypeSlice";
import { setSelectedScoreType } from "../scoreType/scoreTypeSlice";
import { intersectionBy } from "lodash";
import { RegionSummary } from "../../api/regionApi";

function* fetchCitiesSaga() {
  const selectedRegionIds: number[] = yield select(selectSelectedRegionIds);
  const selectedBusinessTypeId: number | undefined = yield select(selectSelectedBusinessTypeId);
  const selectedScoreTypeId: number | undefined = yield select(selectSelectedScoreTypeId);

  if (!selectedRegionIds.length || !selectedBusinessTypeId || !selectedScoreTypeId) {
    yield put(fetchCitiesFailure({ message: "Please select a region, business type, and score type" }));
    return;
  }

  const params: GetCitySummariesParams = {
    region_ids: selectedRegionIds,
    business_type_id: selectedBusinessTypeId,
    score_type_id: selectedScoreTypeId,
  };

  try {
    const citySummaries: CitySummary[] = yield call(getCitySummaries, params);
    yield put(fetchCitiesSuccess(citySummaries));
  } catch (error) {
    yield put(fetchCitiesFailure(error as ErrorResponse));
  }
}

function* watchFetchCities() {
  yield takeLatest(fetchCities.type, fetchCitiesSaga);
}

function* fetchCitiesIfEnabled() {
  const isEnabledCitiesDropdown: boolean = yield select(selectIsEnabledCitiesDropdown);

  if (isEnabledCitiesDropdown) {
    yield put(fetchCities());
  }
}

function* watchSelectedRegionsBusinessTypeScoreType() {
  yield takeLatest([setSelectedRegions.type, setSelectedBusinessType.type, setSelectedScoreType.type], fetchCitiesIfEnabled);
}

function* syncSelectedCitiesSaga() {
  const selectedCities: CitySummary[] = yield select(selectSelectedCities);
  const cities: CitySummary[] = yield select(selectCities);

  const updatedSelectedCities = intersectionBy(selectedCities, cities, (city) => city.id);
  if (updatedSelectedCities.length !== selectedCities.length) {
    yield put(setSelectedCities(updatedSelectedCities));
  }
}

function* syncSelectedCities() {
  yield takeLatest(fetchCitiesSuccess.type, syncSelectedCitiesSaga);
}

function* emptySelectedCitiesIfSelectedRegionsEmpty() {
  const selectedRegions: RegionSummary[] = yield select(selectSelectedRegions);

  if (!selectedRegions.length) {
    yield put(setSelectedCities([]));
  }
}

function* watchSelectedRegions() {
  yield takeLatest(setSelectedRegions.type, emptySelectedCitiesIfSelectedRegionsEmpty);
}

export function* citySaga() {
  yield all([fork(watchFetchCities), fork(watchSelectedRegionsBusinessTypeScoreType), fork(syncSelectedCities), fork(watchSelectedRegions)]);
}
