export interface HeatMapState {
  isVisible: boolean;
  opacity: number;
  colors: HeatMapColor[];
  selectedColor: string;
  selectedColorIdForPicker: number | null;
}

export interface HeatMapColor {
  id: number;
  hash: string;
}

export const heatMapInitialState: HeatMapState = {
  isVisible: true,
  opacity: 50,
  colors: [
    { id: 1, hash: "#3b3b3b" },
    { id: 2, hash: "#0011FF" },
    { id: 3, hash: "#2044FF" },
    { id: 4, hash: "#4066FF" },
    { id: 5, hash: "#0088FF" },
    { id: 6, hash: "#0099FF" },
    { id: 7, hash: "#00B2E0" },
    { id: 8, hash: "#00CCB2" },
    { id: 9, hash: "#00E5A6" },
    { id: 10, hash: "#33FF99" },
    { id: 11, hash: "#66FF66" },
    { id: 12, hash: "#99FF33" },
    { id: 13, hash: "#CCFF00" },
    { id: 14, hash: "#FFCC00" },
    { id: 15, hash: "#FF9900" },
    { id: 16, hash: "#FF6600" },
    { id: 17, hash: "#FF3300" },
    { id: 18, hash: "#FF0000" },
    { id: 19, hash: "#CC0000" },
    { id: 20, hash: "#990000" },
    { id: 21, hash: "#660000" },
  ],
  selectedColorIdForPicker: null,
  selectedColor: "#804BA8",
};
