import { selectSelectedCities } from "./../city/citySelectors";
import { all, call, cancelled, delay, Effect, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { appendScores, fetchScores, fetchScoresFailure, fetchScoresSuccess, resetScores, resetSelectedScore } from "./scoreSlice";
import { GetScoresParams, getScoresStream, LatLonWithSpecificScore } from "../../api/scoreApi";
import { selectSelectedBusinessTypeId } from "../businessType/businessTypeSelectors";
import { selectSelectedScoreTypeId } from "../scoreType/scoreTypeSelectors";
import { selectSelectedCityIds } from "../city/citySelectors";
import { ErrorResponse } from "../../utils/errorHandler";
import { setSelectedCities } from "../city/citySlice";
import { setSelectedBusinessType } from "../businessType/businessTypeSlice";
import { setSelectedScoreType } from "../scoreType/scoreTypeSlice";
import store from "../store";
import { selectSelectedScore } from "./scoreSelectors";
import { EventChannel, eventChannel, END } from "redux-saga";
import axios, { CancelTokenSource } from "axios";

function* watchSelectedCitiesBusinessTypeScoreType() {
  yield takeLatest([setSelectedCities.type, setSelectedBusinessType.type, setSelectedScoreType.type], fetchScoresIfIdsExistSaga);
}
function* fetchScoresIfIdsExistSaga() {
  yield delay(1000);

  const selectedBusinessTypeId: number | undefined = yield select(selectSelectedBusinessTypeId);
  const selectedScoreTypeId: number | undefined = yield select(selectSelectedScoreTypeId);
  const cityIds: number[] = yield select(selectSelectedCityIds);

  if (selectedBusinessTypeId && selectedScoreTypeId && cityIds.length > 0) {
    yield put(fetchScores());
  }
}

function* watchFetchScores() {
  yield takeLatest(fetchScores.type, fetchScoresRestSelectedScoreSaga);
}

function* fetchScoresRestSelectedScoreSaga() {
  yield all([call(fetchScoresSaga), call(resetSelectedScoreSaga)]);
}

function createScoresStreamChannel(params: GetScoresParams): EventChannel<{ data?: LatLonWithSpecificScore[]; error?: ErrorResponse }> {
  return eventChannel((emit) => {
    const cancelToken: CancelTokenSource = axios.CancelToken.source();

    const cancelRequest = () => {
      cancelToken.cancel("Operation canceled by the user.");
    };

    getScoresStream({
      params,
      onData: (data: Array<LatLonWithSpecificScore>) => {
        emit({ data });
      },
      onError: (error: ErrorResponse) => {
        emit({ error });
        emit(END);
      },
      cancelToken,
    });

    return () => cancelRequest();
  });
}

function* fetchScoresSaga(): Generator<Effect, void, any> {
  let channel: EventChannel<{ data?: LatLonWithSpecificScore[]; error?: ErrorResponse }> | undefined;
  try {
    const selectedBusinessTypeId: number | undefined = yield select(selectSelectedBusinessTypeId);
    const selectedScoreTypeId: number | undefined = yield select(selectSelectedScoreTypeId);
    const cityIds: number[] = yield select(selectSelectedCityIds);
    const zoomId: number = 1;

    if (!selectedBusinessTypeId || !selectedScoreTypeId || cityIds.length === 0 || !zoomId) {
      yield put(fetchScoresFailure({ message: "Please select a business type, score type, and city" }));
      return;
    }

    const params: GetScoresParams = {
      business_type_id: selectedBusinessTypeId,
      city_ids: cityIds,
      score_type_id: selectedScoreTypeId,
      zoom_id: zoomId,
    };

    yield put(resetScores());

    channel = yield call(createScoresStreamChannel, params);

    while (true) {
      const { data, error } = yield take(channel!);

      if (data) {
        store.dispatch(appendScores(data));
      } else if (error) {
        yield put(fetchScoresFailure(error));
        break;
      }
    }

    yield put(fetchScoresSuccess());
  } catch (error) {
    yield put(fetchScoresFailure(error as ErrorResponse));
  } finally {
    const isCancelled = yield cancelled();
    if (isCancelled && channel) {
      channel.close();
    }
  }
}

function* resetSelectedScoreSaga() {
  const selectedScore: LatLonWithSpecificScore = yield select(selectSelectedScore);
  if (selectedScore) {
    yield put(resetSelectedScore());
  }
}

function* watchSelectedCities() {
  yield takeLatest(setSelectedCities.type, resetIfNoCitySelected);
}

function* resetIfNoCitySelected() {
  const selectedCities: number[] = yield select(selectSelectedCities);
  if (selectedCities.length === 0) {
    yield all([put(resetScores()), put(resetSelectedScore())]);
  }
}

export function* scoreSaga() {
  yield all([fork(watchSelectedCitiesBusinessTypeScoreType), fork(watchFetchScores), fork(watchSelectedCities)]);
}
