import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialCustomBusinessUploadedFilesState } from "./customBusinessUploadedFilesState";
import { CustomBusinessUploadedFile } from "../../models/CustomBusinessUploadedFile";
import { ErrorResponse } from "../../utils/errorHandler";
import { CustomBusinessProperty } from "../../models/CustomBusinessProperty";
import { removeItemById } from "../../utils/arrayUtils";

export const customBusinessUploadedFilesSlice = createSlice({
  name: "customBusinessUploadedFiles",
  initialState: initialCustomBusinessUploadedFilesState,
  reducers: {
    setSelectedFile: (state, { payload }: PayloadAction<CustomBusinessUploadedFile>) => {
      state.selectedUploadedFile = payload;
    },
    resetSelectedFile: (state) => {
      state.selectedUploadedFile = null;
    },

    setSelectedFileProperty: (state, { payload }: PayloadAction<CustomBusinessProperty>) => {
      state.selectedUploadedFileProperty = payload;
    },
    resetSelectedFileProperty: (state) => {
      state.selectedUploadedFileProperty = null;
    },

    fetchUploadedFiles: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchUploadedFilesSuccess: (state, { payload }: PayloadAction<CustomBusinessUploadedFile[]>) => {
      state.isLoading = false;
      state.uploadedFiles = payload;
    },
    fetchUploadedFilesFailure: (state, { payload }: PayloadAction<ErrorResponse>) => {
      state.isLoading = false;
      state.error = payload;
    },

    deleteUploadedFile: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteUploadedFileSuccess: (state, action: PayloadAction<number>) => {
      state.isLoading = false;
      const deletedFileId = action.payload;

      state.uploadedFiles = removeItemById(state.uploadedFiles, deletedFileId);
    },
    deleteUploadedFileFailure: (state, action: PayloadAction<ErrorResponse>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setSelectedFile,
  resetSelectedFile,
  setSelectedFileProperty,
  resetSelectedFileProperty,
  fetchUploadedFiles,
  fetchUploadedFilesSuccess,
  fetchUploadedFilesFailure,
  deleteUploadedFile,
  deleteUploadedFileSuccess,
  deleteUploadedFileFailure,
} = customBusinessUploadedFilesSlice.actions;

export const customBusinessUploadedFilesReducer = customBusinessUploadedFilesSlice.reducer;
