import { AsyncState } from "../../models/AsyncState";
import { CountrySummary } from "../../models/CountrySummary";

export interface CountryState extends AsyncState {
  selectedCountry: CountrySummary | null;
  countries: CountrySummary[];
}

export const countryInitialState: CountryState = {
  selectedCountry: null,
  countries: [],
  isLoading: false,
  error: null,
};
