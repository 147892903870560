import { CustomBusinessUploadedFile } from "../models/CustomBusinessUploadedFile";
import axiosInstance from "./axios";

export interface GetCustomBusinessUploadedFilesParams {
  country_id: number;
  business_type_id: number;
}

export interface CustomBusinessUploadedFileResponse extends Omit<CustomBusinessUploadedFile, "uploaded_at"> {
  uploaded_at: string;
}

export const getCustomBusinessUploadedFiles = async (params: GetCustomBusinessUploadedFilesParams): Promise<CustomBusinessUploadedFile[]> => {
  const { data } = await axiosInstance.get<CustomBusinessUploadedFileResponse[]>("/custom_business_upload/", { params });

  return data;
};
