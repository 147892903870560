import axiosInstance from "./axios";

export type ScoreType = {
  id: number;
  name: "Accessibility" | "Demand" | "Supply";
};

export const getScoreTypes = async (): Promise<ScoreType[]> => {
  const { data } = await axiosInstance.get<ScoreType[]>("/score_type/get_score_types/");
  return data;
};
