export const parseNumber = (input: string): number | null => {
  const number = Number(input);

  if (isNaN(number) || input.trim() === "") return null;

  return number;
};

export const normalizeInteger = ({
  value,
  originalMin,
  originalMax,
  newMin,
  newMax,
}: {
  value: number;
  originalMin: number;
  originalMax: number;
  newMin: number;
  newMax: number;
}): number | null => {
  if (originalMax === originalMin) {
    return (newMin + newMax) / 2;
  }

  const normalizedValue = ((value - originalMin) / (originalMax - originalMin)) * (newMax - newMin) + newMin;

  if (isNaN(normalizedValue)) {
    return null;
  }

  return Math.round(normalizedValue);
};
