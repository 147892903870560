import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { arrayToIdObject, toIdsArray } from "../../utils/arrayObjectConversions";
import { selectSelectedRegionIds } from "../region/regionSelectors";
import { selectSelectedBusinessTypeId } from "../businessType/businessTypeSelectors";
import { selectSelectedScoreTypeId } from "../scoreType/scoreTypeSelectors";
import { every } from "lodash";

export const selectCity = (state: RootState) => state.city;

export const selectSelectedCities = createSelector(selectCity, (city) => city.selectedCities);
export const selectCities = createSelector(selectCity, (city) => city.cities);
export const selectCityLoading = createSelector(selectCity, (city) => city.isLoading);
export const selectCityError = createSelector(selectCity, (city) => city.error);

// Computed Selectors
export const selectSelectedCityIds = createSelector(selectSelectedCities, toIdsArray);
export const selectCitiesObject = createSelector(selectCities, arrayToIdObject);

export const selectIsEnabledCitiesDropdown = createSelector(
  [selectSelectedRegionIds, selectSelectedBusinessTypeId, selectSelectedScoreTypeId],
  (selectedRegionIds, selectedBusinessTypeId, selectedScoreTypeId): boolean => {
    return every([selectedRegionIds.length > 0, selectedBusinessTypeId, selectedScoreTypeId]);
  }
);
