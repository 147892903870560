import { createSelector } from "reselect";
import { RootState } from "../storeTypes";

export const selectSupplyFacilitiesState = (state: RootState) => state.supplyFacilities;

export const selectSupplyFacilitiesIsExpanded = createSelector(selectSupplyFacilitiesState, (supplyFacilities) => supplyFacilities.isExpanded);

export const selectSupplyFacilities = createSelector(selectSupplyFacilitiesState, (supplyFacilities) => supplyFacilities.facilities);

export const selectSupplyFacilitiesLoading = createSelector(selectSupplyFacilitiesState, (supplyFacilities) => supplyFacilities.isLoading);

export const selectSupplyFacilitiesError = createSelector(selectSupplyFacilitiesState, (supplyFacilities) => supplyFacilities.error);
