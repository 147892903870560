import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { arrayToIdObject } from "../../utils/arrayObjectConversions";
import { countryToFlag } from "../../utils/countryUtils";

export const selectCountry = (state: RootState) => state.country;

export const selectSelectedCountry = createSelector(selectCountry, (country) => country.selectedCountry);
export const selectCountries = createSelector(selectCountry, (country) => country.countries);
export const selectCountriesIsLoading = createSelector(selectCountry, (country) => country.isLoading);
export const selectCountriesError = createSelector(selectCountry, (country) => country.error);

// Computed Selectors
export const selectSelectedCountryId = createSelector(selectSelectedCountry, (selectedCountry) => selectedCountry?.id);
export const selectCountriesObject = createSelector(selectCountries, arrayToIdObject);
export const selectSelectedCountryFlag = createSelector(selectSelectedCountry, (selectedCountry) =>
  selectedCountry ? countryToFlag(selectedCountry.code) : null
);
