import { call, fork, put, all, select, takeLatest } from "redux-saga/effects";
import { getRegionSummaries, GetRegionSummariesParams, RegionSummary } from "../../api/regionApi";
import { fetchRegions, fetchRegionsFailure, fetchRegionsSuccess, setSelectedRegions } from "./regionSlice";
import { ErrorResponse } from "../../utils/errorHandler";
import { selectSelectedCountryId } from "../country/countrySelectors";
import { selectSelectedBusinessTypeId } from "../businessType/businessTypeSelectors";
import { selectSelectedScoreTypeId } from "../scoreType/scoreTypeSelectors";
import { setSelectedCountry } from "../country/countrySlice";
import { setSelectedBusinessType } from "../businessType/businessTypeSlice";
import { setSelectedScoreType } from "../scoreType/scoreTypeSlice";
import { selectIsEnabledRegionsDropdown } from "./regionSelectors";

function* fetchRegionsSaga() {
  const selectedCountryId: number | undefined = yield select(selectSelectedCountryId);
  const selectedBusinessTypeId: number | undefined = yield select(selectSelectedBusinessTypeId);
  const selectedScoreTypeId: number | undefined = yield select(selectSelectedScoreTypeId);

  if (!selectedCountryId || !selectedBusinessTypeId || !selectedScoreTypeId) {
    yield put(fetchRegionsFailure({ message: "Please select a country, business type, and score type" }));
    return;
  }

  const params: GetRegionSummariesParams = {
    country_id: selectedCountryId,
    business_type_id: selectedBusinessTypeId,
    score_type_id: selectedScoreTypeId,
  };

  try {
    const regionSummaries: RegionSummary[] = yield call(getRegionSummaries, params);
    yield put(fetchRegionsSuccess(regionSummaries));
  } catch (error) {
    yield put(fetchRegionsFailure(error as ErrorResponse));
  }
}

function* watchFetchRegions() {
  yield takeLatest(fetchRegions.type, fetchRegionsSaga);
}

function* watchSelectedCountryBusinessTypeScoreType() {
  yield takeLatest([setSelectedCountry.type, setSelectedBusinessType.type, setSelectedScoreType.type], fetchRegionsIfEnabled);
}

function* fetchRegionsIfEnabled() {
  const isEnabledRegionsDropdown: boolean = yield select(selectIsEnabledRegionsDropdown);

  if (isEnabledRegionsDropdown) {
    yield put(fetchRegions());
  }
}

function* emptySelectedRegions() {
  yield put(setSelectedRegions([]));
}

function* watchSelectedCountry() {
  yield takeLatest(setSelectedCountry.type, emptySelectedRegions);
}

export function* regionSaga() {
  yield all([fork(watchFetchRegions), fork(watchSelectedCountryBusinessTypeScoreType), fork(watchSelectedCountry)]);
}
