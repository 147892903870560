import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialDemandFacilitiesState } from "./demandFacilitiesState";
import { ErrorResponse } from "../../utils/errorHandler";
import { Facility } from "../../models/Facility";

const demandFacilitiesSlice = createSlice({
  name: "demandFacilities",
  initialState: initialDemandFacilitiesState,
  reducers: {
    setIsExpandedDemand(state) {
      state.isExpanded = true;
    },
    resetIsExpandedDemand(state) {
      state.isExpanded = false;
    },
    fetchDemandFacilities(state) {
      state.error = null;
      state.isLoading = true;
    },
    fetchDemandFacilitiesSuccess(state, action: PayloadAction<Facility[]>) {
      state.facilities = action.payload;
      state.isLoading = false;
    },
    fetchDemandFacilitiesFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetDemandFacilities(state) {
      state.error = initialDemandFacilitiesState.error;
      state.isLoading = initialDemandFacilitiesState.isLoading;
      state.facilities = initialDemandFacilitiesState.facilities;
    },
  },
});

export const {
  setIsExpandedDemand,
  resetIsExpandedDemand,
  fetchDemandFacilities,
  fetchDemandFacilitiesSuccess,
  fetchDemandFacilitiesFailure,
  resetDemandFacilities,
} = demandFacilitiesSlice.actions;

export const demandFacilitiesReducer = demandFacilitiesSlice.reducer;
