import axiosInstance from "./axios";

export type RegionSummary = {
  id: number;
  name: string;
  lat: number;
  lon: number;
};

export type GetRegionSummariesParams = {
  country_id: number;
  business_type_id: number;
  score_type_id: number;
};

export const getRegionSummaries = async (params: GetRegionSummariesParams): Promise<RegionSummary[]> => {
  const { data } = await axiosInstance.get<RegionSummary[]>("/region/get_region_summaries/", { params });
  return data;
};
