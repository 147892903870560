import { all, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { setSelectedCountry } from "../country/countrySlice";
import { setSelectedRegions } from "../region/regionSlice";
import { selectSelectedCountry } from "../country/countrySelectors";
import { selectSelectedRegions } from "../region/regionSelectors";
import { calculateScoresCenterZoom, calculateCountryCenterZoom, calculateRegionsCenterZoom, calculateCoordnatesCenterZoom } from "../../utils/mapUtils";
import { setMapCenter, setMapZoom } from "./googleMapSlice";
import { RegionSummary } from "../../api/regionApi";
import { appendScores, resetScores } from "../score/scoreSlice";
import { selectScores } from "../score/scoreSelectors";
import { LatLonWithSpecificScore } from "../../api/scoreApi";
import { selectGoogleMapDimensions } from "./googleMapSelectors";
import { Dimensions } from "../../models/Dimensions";
import { fetchCustomBusinessValuesSuccess } from "../customBusinessValue/customBusinessValueSlice";
import { selectCustomBusinessValueMarkersCoordinates } from "../customBusinessValue/customBusinessValueSelectors";
import { Coordinate } from "../../models/Coordinate";
import { CountrySummary } from "../../models/CountrySummary";

function* updateMapForCountry() {
  const selectedCountry: CountrySummary | null = yield select(selectSelectedCountry);
  if (selectedCountry) {
    const { center, zoom } = calculateCountryCenterZoom(selectedCountry);
    yield all([put(setMapCenter(center)), put(setMapZoom(zoom))]);
  }
}

function* updateMapForRegions() {
  const selectedRegions: RegionSummary[] = yield select(selectSelectedRegions);
  const mapDimensions: Dimensions | null = yield select(selectGoogleMapDimensions);

  if (mapDimensions === null) return;

  if (selectedRegions.length) {
    const { center, zoom } = calculateRegionsCenterZoom(selectedRegions, mapDimensions);
    yield all([put(setMapCenter(center)), put(setMapZoom(zoom))]);
  }
}

function* updateMapForScores() {
  yield delay(1000);

  const scores: LatLonWithSpecificScore[] = yield select(selectScores);
  if (scores.length) {
    const mapDimensions: Dimensions | null = yield select(selectGoogleMapDimensions);

    if (mapDimensions === null) return;

    const { center, zoom } = calculateScoresCenterZoom(scores, mapDimensions);

    yield all([put(setMapCenter(center)), put(setMapZoom(zoom))]);
  }
}

function* updateMapForCustomBusinessValues() {
  const coordinate: Coordinate[] = yield select(selectCustomBusinessValueMarkersCoordinates);

  if (coordinate.length) {
    const mapDimensions: Dimensions | null = yield select(selectGoogleMapDimensions);

    if (mapDimensions === null) return;

    const { center, zoom } = calculateCoordnatesCenterZoom(coordinate, mapDimensions);

    yield all([put(setMapCenter(center)), put(setMapZoom(zoom))]);
  }
}

function* watchSelectedCountry() {
  yield takeLatest(setSelectedCountry.type, updateMapForCountry);
}

function* watchSelectedRegions() {
  yield takeLatest(setSelectedRegions.type, updateMapForRegions);
}

function* watchScores() {
  yield takeLatest([appendScores.type, resetScores.type], updateMapForScores);
}

function* watchFetchCustomBusinessValuesSuccess() {
  yield takeLatest(fetchCustomBusinessValuesSuccess.type, updateMapForCustomBusinessValues);
}

export function* googleMapSaga() {
  yield all([fork(watchSelectedCountry), fork(watchSelectedRegions), fork(watchScores), fork(watchFetchCustomBusinessValuesSuccess)]);
}
