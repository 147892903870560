import { selectSelectedScoreTypeId } from "../scoreType/scoreTypeSelectors";
import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { arrayToIdObject, toIdsArray } from "../../utils/arrayObjectConversions";
import { selectSelectedBusinessTypeId } from "../businessType/businessTypeSelectors";
import { selectSelectedCountryId } from "../country/countrySelectors";
import { every } from "lodash";

export const selectRegion = (state: RootState) => state.region;

export const selectSelectedRegions = createSelector(selectRegion, (region) => region.selectedRegions);
export const selectRegions = createSelector(selectRegion, (region) => region.regions);
export const selectRegionLoading = createSelector(selectRegion, (region) => region.isLoading);
export const selectRegionError = createSelector(selectRegion, (region) => region.error);

// Computed Selectors
export const selectSelectedRegionIds = createSelector(selectSelectedRegions, toIdsArray);
export const selectRegionsObject = createSelector(selectRegions, arrayToIdObject);

export const selectIsEnabledRegionsDropdown = createSelector(
  [selectSelectedCountryId, selectSelectedBusinessTypeId, selectSelectedScoreTypeId],
  (selectedCountryId, businessTypeId, scoreTypeId): boolean => {
    return every([selectedCountryId, businessTypeId, scoreTypeId]);
  }
);
