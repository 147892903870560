import { AsyncState } from "../../models/AsyncState";

export interface CustomBusinessUploadState extends AsyncState {
  csvFile: File | null;
}

export const customBusinessUploadInitialState: CustomBusinessUploadState = {
  isLoading: false,
  error: null,
  csvFile: null,
};
