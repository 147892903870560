// uploads/81/properties/22/values/
// {
//   "results": [
//       {
//           "id": 667,
//           "business_name": "Korle Bu Teaching Hospital",
//           "lat": 5.5512,
//           "lon": -0.2235,
//           "value": "1600"
//       },
//       {
//           "id": 670,
//           "business_name": "Kumasi South Hospital",
//           "lat": 6.6881,
//           "lon": -1.6244,
//           "value": "500"
//       },
//       {
//           "id": 673,
//           "business_name": "Tamale Teaching Hospital",
//           "lat": 9.4071,
//           "lon": -0.8595,
//           "value": "800"
//       }
//   ]
// }

import { CustomBusinessValue } from "../models/CustomBusinessValue";
import axiosInstance from "./axios";

export interface CustomBusinessValueResponse<T extends string | number = string> {
  results: CustomBusinessValue<T>[];
}

export interface GetCustomBusinessValuesParams {
  custom_business_uploaded_file_id: number;
  property_id: number;
}

export const getCustomBusinessValues = async ({
  custom_business_uploaded_file_id,
  property_id,
}: GetCustomBusinessValuesParams): Promise<CustomBusinessValue[]> => {
  const { data } = await axiosInstance.get<CustomBusinessValueResponse>(`/uploads/${custom_business_uploaded_file_id}/properties/${property_id}/values/`);

  return data.results;
};
