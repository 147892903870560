import { Facility } from "../models/Facility";
import axiosInstance from "./axios";

export type GetFacilitiesParams = {
  business_type_id: number;
  lat_lon_id: number;
  score_type_id: number;
};

export const getFacilities = async (params: GetFacilitiesParams): Promise<Facility[]> => {
  const { data } = await axiosInstance.get<Facility[]>("/facility/get_ordered_facilities/", { params });
  return data;
};
