import { getLatLonWithScores, GetLatLonWithScoresParams } from "./../../api/latLonApi";
import { takeLatest, select, put, fork, all } from "redux-saga/effects";
import { LatLonWithSpecificScore } from "../../api/scoreApi";
import { selectSelectedBusinessTypeId } from "../businessType/businessTypeSelectors";
import { selectSelectedScore } from "../score/scoreSelectors";
import { fetchLatLonWithScores, fetchLatLonWithScoresFailure, fetchLatLonWithScoresSuccess, resetLatLonWithScores } from "./latLonSlice";
import { ErrorResponse } from "../../utils/errorHandler";
import { LatLon, LatLonScore } from "../score/lat_lon_with_scores";
import { resetSelectedScore, setSelectedScore } from "../score/scoreSlice";

function* watchResetSelectedScore() {
  yield takeLatest([resetSelectedScore.type], resetSelectedScoreSaga);
}
function* resetSelectedScoreSaga() {
  yield put(resetLatLonWithScores());
}

function* watchSetSelectedScore() {
  yield takeLatest([setSelectedScore.type], fetchCoordinateScoresSaga);
}
function* fetchCoordinateScoresSaga() {
  console.log("first");
  yield put(fetchLatLonWithScores());
}

function* watchFetchScores() {
  yield takeLatest(setSelectedScore.type, fetchScoresSaga);
}
function* fetchScoresSaga() {
  const selectedScore: LatLonWithSpecificScore | null = yield select(selectSelectedScore);
  const selectedBusinessTypeId: number | undefined = yield select(selectSelectedBusinessTypeId);

  if (!selectedScore || !selectedBusinessTypeId) {
    const failure: ErrorResponse = { message: "Please select a score and business type" };
    put(fetchLatLonWithScoresFailure(failure));
    return;
  }

  const params: GetLatLonWithScoresParams = {
    latLonId: selectedScore.id,
    businessTypeId: selectedBusinessTypeId,
  };

  try {
    const latLonWithScores: LatLon<LatLonScore[]> = yield getLatLonWithScores(params);
    yield put(fetchLatLonWithScoresSuccess(latLonWithScores));
  } catch (error) {
    put(fetchLatLonWithScoresFailure(error as ErrorResponse));
  }
}

export function* latLonSaga() {
  yield all([fork(watchResetSelectedScore), fork(watchSetSelectedScore), fork(watchFetchScores)]);
}
