import axios, { AxiosError, AxiosProgressEvent, CancelTokenSource } from "axios";
import { errorHandler, ErrorResponse } from "../utils/errorHandler";
import { handleStreamProgress } from "../utils/handleStreamProgress";
import store from "../redux/store";
import { selectAccessToken, selectRefreshToken } from "../redux/auth/authSelectors";
import { logout, refreshTokenSuccess } from "../redux/auth/authSlice";
import * as authApi from "../api/authApi";

export const BASE_URL = import.meta.env.VITE_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = selectAccessToken(state);

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config;

    const standardizedError: ErrorResponse = errorHandler(error);

    if (standardizedError.statusCode === 401 && originalRequest && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = selectRefreshToken(store.getState());

      if (refreshToken) {
        try {
          const response = await authApi.refreshToken(refreshToken);
          store.dispatch(refreshTokenSuccess({ accessToken: response.access }));

          originalRequest.headers["Authorization"] = `Bearer ${response.access}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          store.dispatch(logout());
          const standardizedRefreshError = errorHandler(refreshError);
          return Promise.reject(standardizedRefreshError);
        }
      } else {
        store.dispatch(logout());
      }
    }

    return Promise.reject(standardizedError);
  }
);

export const fetchStream = async <T extends Record<string, any>>(
  url: string,
  {
    params,
    onData,
    onError,
    cancelToken,
  }: {
    params: Record<string, any>;
    onData: (data: Array<T>) => void;
    onError: (error: ErrorResponse) => void;
    cancelToken?: CancelTokenSource;
  }
): Promise<void> => {
  let lastBufferLength = 0;

  try {
    await axiosInstance.get(url, {
      params,
      cancelToken: cancelToken?.token,
      onDownloadProgress: (event: AxiosProgressEvent) => {
        handleStreamProgress<T>(
          event,
          lastBufferLength,
          (parsedChunk, lastBracketIndex) => {
            if (cancelToken && cancelToken.token.reason) return; // Check for cancellation

            onData(parsedChunk);

            if (lastBracketIndex > 0) {
              lastBufferLength += lastBracketIndex + 1;
            }
          },
          onError
        );
      },
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
    } else {
      onError(error as ErrorResponse);
    }
  }
};

export type FetchStreamType<T extends Record<string, any>, P extends object> = ({
  params,
  onData,
  onError,
}: {
  params: P;
  onData: (data: Array<T>) => void;
  onError: (error: ErrorResponse) => void;
}) => Promise<void>;

export default axiosInstance;
