import { all, call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { setSelectedCities } from "../city/citySlice";
import { selectSelectedCities, selectSelectedCityIds } from "../city/citySelectors";
import {
  fetchAverageScores,
  fetchAverageScoresFailure,
  fetchAverageScoresSuccess,
  resetAverageScores,
  setCityAveragesTabIsOpened,
} from "./citiesAverageScoreSlice";
import { selectSelectedBusinessType } from "../businessType/businessTypeSelectors";
import { Scores } from "../../models/Scores";
import { ErrorResponse } from "../../utils/errorHandler";
import { getAverageFilteredScores, GetAverageFilteredScoresParams } from "../../api/citiesAverageScoreApi";
import { BusinessType } from "../../models/BusinessType";
import { ScoreType } from "../../api/scoreTypeApi";
import { selectSelectedScoreType } from "../scoreType/scoreTypeSelectors";
import { setSelectedScoreType } from "../scoreType/scoreTypeSlice";
import { setSelectedBusinessType } from "../businessType/businessTypeSlice";
import { selectCityAveragesTabIsOpened } from "./citiesAverageScoreSelectors";
import { CitySummary } from "../../api/cityApi";

function* watchSelectedCities() {
  yield takeLatest(
    [setSelectedCities.type, setSelectedScoreType.type, setSelectedBusinessType.type, setCityAveragesTabIsOpened.type],
    resetOrFetchAverageScoresBasedOnSelectedCitiesSaga
  );
}

function* resetOrFetchAverageScoresBasedOnSelectedCitiesSaga() {
  const selectedCities: CitySummary[] = yield select(selectSelectedCities);
  const se1ectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
  const selectedScoreType: ScoreType | null = yield select(selectSelectedScoreType);
  const cityAveragesTabIsOpened: boolean = yield select(selectCityAveragesTabIsOpened);

  if (selectedCities.length > 0 && se1ectedBusinessType && selectedScoreType && cityAveragesTabIsOpened) {
    yield put(fetchAverageScores());
  } else {
    yield put(resetAverageScores());
  }
}

function* watchFetchAverageScores() {
  yield takeLatest(fetchAverageScores.type, fetchAverageScoresSaga);
}

function* fetchAverageScoresSaga() {
  yield delay(1000);

  try {
    const selectedCityIds: number[] = yield select(selectSelectedCityIds);
    const selectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
    const selectedScoreType: ScoreType | null = yield select(selectSelectedScoreType);
    const zoomId: number = 1;

    if (selectedCityIds.length === 0 || !selectedBusinessType || !selectedScoreType || !zoomId) {
      yield put(fetchAverageScoresFailure({ message: "Please select a business type, score type, and at least one city" }));
      return;
    }

    const params: GetAverageFilteredScoresParams = {
      business_type_id: selectedBusinessType!.id,
      city_ids: selectedCityIds,
      score_type_id: selectedScoreType.id,
      zoom_id: zoomId,
    };

    const averageScores: Scores = yield call(getAverageFilteredScores, params);
    yield put(fetchAverageScoresSuccess(averageScores));
  } catch (error) {
    yield put(fetchAverageScoresFailure(error as ErrorResponse));
  }
}

export function* citiesAverageScoreSaga() {
  yield all([fork(watchFetchAverageScores), fork(watchSelectedCities)]);
}
