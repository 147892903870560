export const extractObjects = <T extends Record<string, any>>(
  str: string
): { objects: Array<T>; lastBraceIndex: number } => {
  const objects: Array<T> = [] as T[];
  let braceCount = 0;
  let start = -1;
  let lastBraceIndex = -1;

  for (let i = 0; i < str.length; i++) {
    if (str[i] === "{") {
      if (braceCount === 0) start = i;
      braceCount++;
    } else if (str[i] === "}") {
      if (braceCount > 0) braceCount--;
      if (braceCount === 0 && start !== -1) {
        const extracted = str.substring(start, i + 1);
        try {
          const extractedParsed = JSON.parse(extracted);
          objects.push(extractedParsed);
          lastBraceIndex = i;
        } catch (e) {
          // Invalid JSON, ignore
        }
        start = -1;
      }
    }
  }

  return { objects, lastBraceIndex };
};
