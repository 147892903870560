import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialCustomBusinessValueState } from "./customBusinessValueState";
import { CustomBusinessValue } from "../../models/CustomBusinessValue";

export const customBusinessValueSlice = createSlice({
  name: "customBusinessValue",
  initialState: initialCustomBusinessValueState,
  reducers: {
    setCustomBusinessValue: (state, { payload }: PayloadAction<CustomBusinessValue<string> | null>) => {
      state.selectedCustomBusinessValue = payload;
    },
    resetSelectedCustomBusinessValue: (state) => {
      state.selectedCustomBusinessValue = initialCustomBusinessValueState.selectedCustomBusinessValue;
    },

    fetchCustomBusinessValues: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCustomBusinessValuesSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.customBusinessValues = payload;
    },
    fetchCustomBusinessValuesFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    resetCustomBusinessValues: (state) => {
      state.customBusinessValues = initialCustomBusinessValueState.customBusinessValues;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const {
  setCustomBusinessValue,
  resetSelectedCustomBusinessValue,
  fetchCustomBusinessValues,
  fetchCustomBusinessValuesSuccess,
  fetchCustomBusinessValuesFailure,
  resetCustomBusinessValues,
} = customBusinessValueSlice.actions;

export const customBusinessValueReducer = customBusinessValueSlice.reducer;
