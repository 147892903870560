import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { arrayToIdObject } from "../../utils/arrayObjectConversions";

export const selectScoreTypeState = (state: RootState) => state.scoreType;

export const selectSelectedScoreType = createSelector(selectScoreTypeState, (scoreType) => scoreType.selectedScoreType);
export const selectScoreTypes = createSelector(selectScoreTypeState, (scoreType) => scoreType.scoreTypes);
export const selectScoreTypesLoading = createSelector(selectScoreTypeState, (scoreType) => scoreType.isLoading);
export const selectScoreTypesError = createSelector(selectScoreTypeState, (scoreType) => scoreType.error);

// Computed Selectors
export const selectSelectedScoreTypeId = createSelector(selectSelectedScoreType, (selectedScoreType) => selectedScoreType?.id);
export const selectScoreTypeObjects = createSelector(selectScoreTypes, arrayToIdObject);
