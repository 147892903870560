import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { selectSelectedCountry } from "../country/countrySelectors";
import { selectSelectedBusinessType } from "../businessType/businessTypeSelectors";

export const selectCustomBusinessUploadState = (state: RootState) => state.customBusinessUpload;

export const selectCustomBusinessUploadCsvFile = createSelector(selectCustomBusinessUploadState, (uploadState) => uploadState.csvFile);
export const selectCustomBusinessUploadLoading = createSelector(selectCustomBusinessUploadState, (uploadState) => uploadState.isLoading);
export const selectCustomBusinessUploadError = createSelector(selectCustomBusinessUploadState, (uploadState) => uploadState.error);

// Compund selectors
export const selectCustomBusinessUploadEnabled = createSelector(
  [selectSelectedCountry, selectSelectedBusinessType],
  (selectedCountry, selectedBusinessType): boolean => !!selectedCountry && !!selectedBusinessType
);
