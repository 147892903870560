import { AsyncState } from "../../models/AsyncState";
import { CustomBusinessValue } from "../../models/CustomBusinessValue";

export interface CustomBusinessValueState extends AsyncState {
  selectedCustomBusinessValue: CustomBusinessValue<string> | null;
  customBusinessValues: CustomBusinessValue<string>[];
}

export const initialCustomBusinessValueState: CustomBusinessValueState = {
  selectedCustomBusinessValue: null,
  customBusinessValues: [],
  isLoading: false,
  error: null,
};
