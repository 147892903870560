import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { regionInitialState } from "./regionState";
import { RegionSummary } from "../../api/regionApi";
import { ErrorResponse } from "../../utils/errorHandler";

const regionSlice = createSlice({
  name: "region",
  initialState: regionInitialState,
  reducers: {
    setSelectedRegions(state, action: PayloadAction<RegionSummary[]>) {
      state.selectedRegions = action.payload;
    },
    fetchRegions: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchRegionsSuccess: (state, action: PayloadAction<RegionSummary[]>) => {
      state.isLoading = false;
      state.regions = action.payload;
    },
    fetchRegionsFailure: (state, action: PayloadAction<ErrorResponse>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { setSelectedRegions, fetchRegions, fetchRegionsSuccess, fetchRegionsFailure } = regionSlice.actions;

export const regionReducer = regionSlice.reducer;
