import { AxiosProgressEvent } from "axios";
import { extractObjects } from "./extractObjects";
import { ErrorResponse } from "./errorHandler";

export function handleStreamProgress<T extends Record<string, any>>(
  progressEvent: AxiosProgressEvent,
  lastBufferLength: number,
  onData: (data: Array<T>, lastBracketIndex: number) => void,
  onError: (error: ErrorResponse) => void
) {
  const chunk: string | null = progressEvent.event?.currentTarget?.response?.slice(lastBufferLength);

  if (chunk) {
    try {
      const { objects, lastBraceIndex } = extractObjects<T>(chunk);

      if (objects && objects.length > 0 && objects[0].event === "error") {
        onError({
          message: objects[0].data.error || "An unknown error occurred",
        });
        return;
      }

      onData(objects, lastBraceIndex);
    } catch (error) {
      console.log({ chunk, error });
      onError({
        message: "Error parsing stream data",
      });
    }
  }
}
