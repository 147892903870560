import { LatLon, LatLonScore } from "../redux/score/lat_lon_with_scores";
import axiosInstance from "./axios";

export interface GetLatLonWithScoresParams {
  latLonId: number;
  businessTypeId: number;
}

export const getLatLonWithScores = async (params: GetLatLonWithScoresParams) => {
  const { data } = await axiosInstance.get<LatLon<LatLonScore[]>>(`/lat_lon/with_scores/`, {
    params: { id: params.latLonId, business_type_id: params.businessTypeId },
  });

  return data;
};
