import { deleteCustomBusiness } from "../../api/customBusinessUploadApi";
import { deleteUploadedFile, deleteUploadedFileSuccess, deleteUploadedFileFailure } from "./customBusinessUploadedFilesSlice";

import { getCustomBusinessUploadedFiles, GetCustomBusinessUploadedFilesParams } from "../../api/customBusinessUploadedFilesApi";
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchUploadedFiles,
  fetchUploadedFilesFailure,
  fetchUploadedFilesSuccess,
  resetSelectedFile,
  resetSelectedFileProperty,
} from "./customBusinessUploadedFilesSlice";
import { selectSelectedCountryId } from "../country/countrySelectors";
import { BusinessType } from "../../models/BusinessType";
import { CustomBusinessUploadedFile } from "../../models/CustomBusinessUploadedFile";
import { ErrorResponse } from "../../utils/errorHandler";
import { setSelectedCountry } from "../country/countrySlice";
import { setSelectedBusinessType } from "../businessType/businessTypeSlice";
import { selectSelectedBusinessType } from "../businessType/businessTypeSelectors";
import { selectCustomBusinessUploadedFiles, selectSelectedCustomBusinessUploadedFile } from "./customBusinessUploadedFilesSelectors";
import { isEmpty } from "lodash";
import { uploadCustomBusinessSuccess } from "../customBusinessUpload/customBusinessUploadSlice";
import { PayloadAction } from "@reduxjs/toolkit";

function* watchSelectedCountryBusinessType() {
  // TODO: remove this uploadCustomBusinessSuccess.type and make it appends uploaded file to the list
  yield takeLatest([setSelectedCountry.type, setSelectedBusinessType.type, uploadCustomBusinessSuccess.type], fetchUploadedFilesSaga);
}

function* fetchUploadedFilesSaga() {
  yield put(fetchUploadedFiles());
}

function* watchFetchUploadedFiles() {
  yield takeLatest(fetchUploadedFiles.type, fetchCustomBusinessUploadedFilesSaga);
}

function* fetchCustomBusinessUploadedFilesSaga() {
  const countryId: number | undefined = yield select(selectSelectedCountryId);

  const businessType: BusinessType | undefined = yield select(selectSelectedBusinessType);
  const businessTypeId: number | undefined = businessType?.id;

  if (!countryId || !businessTypeId) {
    yield put(fetchUploadedFilesFailure({ message: "Country or business type is not selected" }));
    return;
  }

  const params: GetCustomBusinessUploadedFilesParams = {
    country_id: countryId,
    business_type_id: businessTypeId,
  };

  try {
    const uploadedFiles: CustomBusinessUploadedFile[] = yield call(getCustomBusinessUploadedFiles, params);
    yield put(fetchUploadedFilesSuccess(uploadedFiles));
  } catch (error) {
    yield put(fetchUploadedFilesFailure(error as ErrorResponse));
  }
}

function* watchCustomBusinessUploadedFilesChanges() {
  yield takeLatest(fetchUploadedFilesSuccess.type, deselectIfNotIncludedInCustomBusinessUploadedFile);
}

function* deselectIfNotIncludedInCustomBusinessUploadedFile() {
  const selectedCustomBusinessUploadedFile: CustomBusinessUploadedFile | null = yield select(selectSelectedCustomBusinessUploadedFile);
  if (!selectedCustomBusinessUploadedFile) return;

  const customBusinessUploadedFiles: CustomBusinessUploadedFile[] = yield select(selectCustomBusinessUploadedFiles);

  if (isEmpty(customBusinessUploadedFiles)) {
    yield put(resetSelectedFile());
    return;
  }

  const isFileIncluded: boolean = customBusinessUploadedFiles.some((file) => file.id === selectedCustomBusinessUploadedFile.id);

  if (!isFileIncluded) {
    yield put(resetSelectedFile());
  }
}

function* watchResetSelectedFile() {
  yield takeLatest(resetSelectedFile.type, resetSelectedFilePropertySaga);
}

function* resetSelectedFilePropertySaga() {
  yield put(resetSelectedFileProperty());
}

function* performDeleteUploadedFile({ payload }: PayloadAction<number>) {
  try {
    yield call(deleteCustomBusiness, payload);
    yield put(deleteUploadedFileSuccess(payload));

    const selectedFile: CustomBusinessUploadedFile | null = yield select(selectSelectedCustomBusinessUploadedFile);
    if (selectedFile?.id === payload) {
      yield put(resetSelectedFile());
    }
  } catch (error) {
    yield put(deleteUploadedFileFailure(error as ErrorResponse));
  }
}

function* watchDeleteUploadedFile() {
  yield takeLatest(deleteUploadedFile.type, performDeleteUploadedFile);
}

export function* customBusinessUploadedFilesSaga() {
  yield all([
    fork(watchSelectedCountryBusinessType),
    fork(watchFetchUploadedFiles),
    fork(watchCustomBusinessUploadedFilesChanges),
    fork(watchResetSelectedFile),
    fork(watchDeleteUploadedFile),
  ]);
}
