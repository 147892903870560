import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { arrayToIdObject } from "../../utils/arrayObjectConversions";

export const selectBusinessType = (state: RootState) => state.businessType;

export const selectBusinessTypes = createSelector(selectBusinessType, (businessType) => businessType.businessTypes);
export const selectSelectedBusinessType = createSelector(selectBusinessType, (businessType) => businessType.selectedBusinessType);
export const selectBusinessTypeLoading = createSelector(selectBusinessType, (businessType) => businessType.isLoading);
export const selectBusinessTypeError = createSelector(selectBusinessType, (businessType) => businessType.error);

// Computed Selectors
export const selectSelectedBusinessTypeId = createSelector(selectSelectedBusinessType, (selectedBusinessType) => selectedBusinessType?.id);
export const selectBusinessTypeObjects = createSelector(selectBusinessTypes, arrayToIdObject);
