import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResponse } from "../../utils/errorHandler";
import { customBusinessUploadInitialState } from "./customBusinessUploadState";

const customBusinessUploadSlice = createSlice({
  name: "customBusinessUpload",
  initialState: customBusinessUploadInitialState,
  reducers: {
    setCustomBusinessUploadCsvFile: (state, action: PayloadAction<File>) => {
      state.csvFile = action.payload;
    },
    resetCustomBusinessUploadCsvFile: (state) => {
      state.csvFile = null;
    },
    uploadCustomBusiness: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    uploadCustomBusinessSuccess: (state) => {
      state.isLoading = false;
      state.csvFile = null;
    },
    uploadCustomBusinessFailure: (state, action: PayloadAction<ErrorResponse>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setCustomBusinessUploadCsvFile,
  resetCustomBusinessUploadCsvFile,
  uploadCustomBusiness,
  uploadCustomBusinessSuccess,
  uploadCustomBusinessFailure,
} = customBusinessUploadSlice.actions;

export const customBusinessUploadReducer = customBusinessUploadSlice.reducer;
