import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import ProtectedRoute from "../components/Auth/ProtectedRoute";
import AuthRedirectRoute from "../components/Auth/AuthRedirectRoute";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import { Fragment } from "react/jsx-runtime";
import { lazy } from "react";

const Home = lazy(() => import("../pages/Home/Home"));
const SignInPage = lazy(() => import("../pages/SignInPage/SignInPage"));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      {/* Public Routes */}
      <Route
        path="/sign-in"
        element={
          <AuthRedirectRoute>
            <SignInPage />
          </AuthRedirectRoute>
        }
      />

      {/* Protected Routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />

      {/* Catch-all Route */}
      <Route path="*" element={<NotFoundPage />} />
    </Fragment>
  )
);
