import { Credentials } from "../models/Credentials";
import axiosInstance from "./axios";

export interface TokenResponse {
  access: string;
  refresh: string;
}
export interface RefreshTokenResponse {
  access: string;
}

export interface VerifyTokenResponse {
  valid: boolean;
}

export const getToken = async (credentials: Credentials): Promise<TokenResponse> => {
  const { data } = await axiosInstance.post<TokenResponse>("/auth/token/", credentials);
  return data;
};

export const refreshToken = async (refreshToken: string): Promise<RefreshTokenResponse> => {
  const { data } = await axiosInstance.post<RefreshTokenResponse>("/auth/token/refresh/", { refresh: refreshToken });
  return data;
};
