import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { getCountrySummaries } from "../../api/countryApi";
import { fetchCountries, fetchCountriesFailure, fetchCountriesSuccess } from "./countrySlice";
import { ErrorResponse } from "../../utils/errorHandler";
import { CountrySummary } from "../../models/CountrySummary";

function* watchFetchCountries() {
  yield takeLatest(fetchCountries.type, fetchCountriesSaga);
}

function* fetchCountriesSaga() {
  try {
    const countrySummaries: CountrySummary[] = yield call(getCountrySummaries);
    yield put(fetchCountriesSuccess(countrySummaries));
  } catch (error) {
    yield put(fetchCountriesFailure(error as ErrorResponse));
  }
}

export function* countrySagas() {
  yield all([fork(watchFetchCountries)]);
}
