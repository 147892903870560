import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { arrayToIdObject } from "../../utils/arrayObjectConversions";

export const selectCustomBusinessUploadedFilesState = (state: RootState) => state.customBusinessUploadedFiles;

export const selectCustomBusinessUploadedFiles = createSelector(
  selectCustomBusinessUploadedFilesState,
  (customBusinessUploadedFiles) => customBusinessUploadedFiles.uploadedFiles
);
export const selectCustomBusinessIsLoading = createSelector(
  selectCustomBusinessUploadedFilesState,
  (customBusinessUploadedFiles) => customBusinessUploadedFiles.isLoading
);
export const selectCustomBusinessError = createSelector(
  selectCustomBusinessUploadedFilesState,
  (customBusinessUploadedFiles) => customBusinessUploadedFiles.error
);
export const selectSelectedCustomBusinessUploadedFileProperty = createSelector(
  selectCustomBusinessUploadedFilesState,
  (customBusinessUploadedFiles) => customBusinessUploadedFiles.selectedUploadedFileProperty
);

export const selectSelectedCustomBusinessUploadedFile = createSelector(
  selectCustomBusinessUploadedFilesState,
  (customBusinessUploadedFiles) => customBusinessUploadedFiles.selectedUploadedFile
);
export const selectSelectedCustomBusinessUploadedFileProperties = createSelector(
  selectSelectedCustomBusinessUploadedFile,
  (selectedCustomBusinessUploadedFile) => selectedCustomBusinessUploadedFile?.properties ?? []
);

// Computed selectors
export const selectSelectedCustomBusinessUploadedFilePropertiesObject = createSelector(
  selectSelectedCustomBusinessUploadedFileProperties,
  (selectedCustomBusinessUploadedFileProperties) =>
    selectedCustomBusinessUploadedFileProperties ? arrayToIdObject(selectedCustomBusinessUploadedFileProperties) : {}
);
