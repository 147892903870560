import { AsyncState } from "../../models/AsyncState";
import { LatLon, LatLonScore } from "../score/lat_lon_with_scores";

export interface LatLonState extends AsyncState {
  latLon: LatLon<LatLonScore[]> | null;
}

export const latLonInitialState: LatLonState = {
  latLon: null,
  isLoading: false,
  error: null,
};
