import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { uploadCustomBusiness, uploadCustomBusinessSuccess, uploadCustomBusinessFailure, setCustomBusinessUploadCsvFile } from "./customBusinessUploadSlice";
import { uploadCustomBusiness as uploadCustomBusinessApi, CustomBusinessUploadParams } from "../../api/customBusinessUploadApi";
import { ErrorResponse } from "../../utils/errorHandler";
import { selectSelectedBusinessType } from "../businessType/businessTypeSelectors";
import { selectSelectedCountry } from "../country/countrySelectors";
import { selectCustomBusinessUploadCsvFile } from "./customBusinessUploadSelectors";
import { BusinessType } from "../../models/BusinessType";
import { CountrySummary } from "../../models/CountrySummary";

function* performManualUploadCustomBusiness() {
  try {
    const selectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
    const selectedCountry: CountrySummary | null = yield select(selectSelectedCountry);
    const csvFile: File | null = yield select(selectCustomBusinessUploadCsvFile);

    console.log({ selectedBusinessType, selectedCountry, csvFile });

    if (!selectedBusinessType || !selectedCountry || !csvFile) {
      yield put(uploadCustomBusinessFailure({ message: "Missing required fields." }));
      return;
    }

    const uploadParams: CustomBusinessUploadParams = {
      country_id: selectedCountry.id,
      business_type_id: selectedBusinessType.id,
      file: csvFile,
    };

    yield call(uploadCustomBusinessApi, uploadParams);
    yield put(uploadCustomBusinessSuccess());
  } catch (error) {
    yield put(uploadCustomBusinessFailure(error as ErrorResponse));
  }
}

function* watchManualUploadCustomBusiness() {
  yield takeLatest(uploadCustomBusiness.type, performManualUploadCustomBusiness);
}

function* handleCsvFileChange() {
  const selectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
  const selectedCountry: CountrySummary | null = yield select(selectSelectedCountry);
  const csvFile: File | null = yield select(selectCustomBusinessUploadCsvFile);

  if (selectedBusinessType && selectedCountry && csvFile) {
    yield put(uploadCustomBusiness());
  }
}

function* watchCsvFileChange() {
  yield takeLatest(setCustomBusinessUploadCsvFile.type, handleCsvFileChange);
}

export function* customBusinessUploadSaga() {
  yield all([watchManualUploadCustomBusiness(), watchCsvFileChange()]);
}
