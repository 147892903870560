import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { latLonInitialState } from "./latLonState";
import { LatLon, LatLonScore } from "../score/lat_lon_with_scores";
import { ErrorResponse } from "../../utils/errorHandler";

export const latLonSlice = createSlice({
  name: "latLon",
  initialState: latLonInitialState,
  reducers: {
    fetchLatLonWithScores: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchLatLonWithScoresSuccess: (state, { payload }: PayloadAction<LatLon<LatLonScore[]>>) => {
      state.isLoading = false;
      state.latLon = payload;
    },
    fetchLatLonWithScoresFailure: (state, { payload }: PayloadAction<ErrorResponse>) => {
      state.isLoading = false;
      state.error = payload;
    },
    resetLatLonWithScores: (state) => {
      state.latLon = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { fetchLatLonWithScores, fetchLatLonWithScoresSuccess, fetchLatLonWithScoresFailure, resetLatLonWithScores } = latLonSlice.actions;

export const latLonReducer = latLonSlice.reducer;
