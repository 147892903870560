import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HeatMapColor, heatMapInitialState } from "./heatMapState";
import { last } from "lodash";
import { updateById } from "../../utils/arrayUtils";

const heatMapSlice = createSlice({
  name: "heatMap",
  initialState: heatMapInitialState,
  reducers: {
    setHeatMapIsVisible(state, action: PayloadAction<boolean>) {
      state.isVisible = action.payload;
    },
    setHeatMapOpacity(state, action: PayloadAction<number>) {
      state.opacity = action.payload;
    },
    setHeatMapColors(state, action: PayloadAction<HeatMapColor[]>) {
      state.colors = action.payload;
    },
    addHeatMapColor(state, action: PayloadAction<string>) {
      const newColor: HeatMapColor = { id: last(state.colors)!.id + 1, hash: action.payload };
      state.colors.push(newColor);
    },
    changeHeatMapColor(state, action: PayloadAction<{ color: HeatMapColor; newHash: string }>) {
      state.colors = updateById(state.colors, action.payload.color.id, { hash: action.payload.newHash });
    },
    removeHeatMapColor(state, action: PayloadAction<HeatMapColor>) {
      state.colors = state.colors.filter((color) => color.id !== action.payload.id);
    },
    setHeatMapSelectedColor(state, action: PayloadAction<string>) {
      state.selectedColor = action.payload;
    },
    resetHeatMapCustomizations(state) {
      state.colors = heatMapInitialState.colors;
      state.opacity = heatMapInitialState.opacity;
      state.isVisible = heatMapInitialState.isVisible;
    },
  },
});

export const {
  setHeatMapIsVisible,
  setHeatMapOpacity,
  setHeatMapColors,
  addHeatMapColor,
  changeHeatMapColor,
  removeHeatMapColor,
  setHeatMapSelectedColor,
  resetHeatMapCustomizations,
} = heatMapSlice.actions;

export const heatMapReducer = heatMapSlice.reducer;
