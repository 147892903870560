import { AsyncState } from "../../models/AsyncState";

export interface AuthState extends AsyncState {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;
}

export const initialAuthState: AuthState = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
};
