import { normalizeInteger } from "./numberUtils";

export const selectColorForValue = ({
  numericValue,
  colorPalette,
  minNumericRange = 0,
  maxNumericRange = 100,
}: {
  numericValue: number;
  colorPalette: string[];
  minNumericRange?: number;
  maxNumericRange?: number;
}): string => {
  const index =
    normalizeInteger({
      value: numericValue,
      originalMin: minNumericRange,
      originalMax: maxNumericRange,
      newMin: 0,
      newMax: colorPalette.length - 1,
    }) ?? 0;

  const clampedIndex = Math.max(0, Math.min(colorPalette.length - 1, index));

  return colorPalette[clampedIndex];
};
