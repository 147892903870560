import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import { orange } from "@ant-design/colors";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

// TODO: remove
const queryClient = new QueryClient();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            componentSize="large"
            theme={{
              components: {
                Input: {
                  borderRadius: 8,
                  paddingBlockLG: 10,
                },
                Select: {
                  colorBgContainerDisabled: "#d9d9d9",
                },
                Button: {
                  lineHeightLG: 20,
                },
              },
              token: {
                colorPrimary: orange.primary,
              },
            }}
          >
            <App />
          </ConfigProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);
