import { createSelector } from "reselect";
import { RootState } from "../storeTypes";

export const selectHeatMap = (state: RootState) => state.heatMap;

export const selectHeatMapIsVisible = createSelector(selectHeatMap, (heatMap) => heatMap.isVisible);
export const selectHeatMapOpacity = createSelector(selectHeatMap, (heatMap) => heatMap.opacity);
export const selectHeatMapColors = createSelector(selectHeatMap, (heatMap) => heatMap.colors);
export const selectHeatMapSelectedColor = createSelector(selectHeatMap, (heatMap) => heatMap.selectedColor);

// Computed selectors
export const selectHeatMapColorsLength = createSelector(selectHeatMapColors, (colors) => colors.length);
export const selectHeatMapDeleteColorEnabled = createSelector(selectHeatMapColorsLength, (colorsLength) => colorsLength > 3);
export const selectHeatMapColorHashs = createSelector(selectHeatMapColors, (colors) => colors.map((color) => color.hash));
export const selectHeatMapNormalizedOpacity = createSelector(selectHeatMapOpacity, (opacity) => opacity / 100);
export const selectHeatMapNormalizedStrokeOpacity = createSelector(selectHeatMapNormalizedOpacity, (normalizedOpacity) =>
  normalizedOpacity <= 0.9 ? normalizedOpacity + 0.1 : 1
);
