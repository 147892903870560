import { configureStore, Middleware } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";
import logger from "redux-logger";
import { rootSaga } from "./rootSaga";
import storage from "redux-persist/lib/storage";
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import { uploadCustomBusiness } from "./customBusinessUpload/customBusinessUploadSlice";

const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "heatMap", "businessType", "scoreType"],
};

const middleware: Array<Middleware> = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, uploadCustomBusiness.type],
      },
    }).concat(middleware),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
