import { CitySummary } from "../../api/cityApi";
import { AsyncState } from "../../models/AsyncState";

export interface CityState extends AsyncState {
  selectedCities: CitySummary[];
  cities: CitySummary[];
}

export const cityInitialState: CityState = {
  selectedCities: [],
  cities: [],
  isLoading: false,
  error: null,
};
