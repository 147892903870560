import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { googleMapInitialState } from "./googleMapState";
import { Coordinate } from "../../models/Coordinate";
import { Bounds } from "../../models/Bounds";
import { Dimensions } from "../../models/Dimensions";

const googleMapSlice = createSlice({
  name: "googleMap",
  initialState: googleMapInitialState,
  reducers: {
    setMapCenter(state, action: PayloadAction<Coordinate | null>) {
      state.center = action.payload;
    },
    setMapZoom(state, action: PayloadAction<number>) {
      state.zoom = action.payload;
    },
    setMapBounds(state, action: PayloadAction<Bounds | null>) {
      state.bounds = action.payload;
    },
    setMapDimensions(state, action: PayloadAction<Dimensions | null>) {
      state.dimensions = action.payload;
    },
  },
});

export const { setMapCenter, setMapZoom, setMapBounds, setMapDimensions } = googleMapSlice.actions;
export const googleMapReducer = googleMapSlice.reducer;
