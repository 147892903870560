import { Scores } from "../models/Scores";
import axiosInstance from "./axios";

export interface GetAverageFilteredScoresParams {
  city_ids: number[];
  zoom_id: number;
  business_type_id: number;
  score_type_id: number;
}

export const getAverageFilteredScores = async (params: GetAverageFilteredScoresParams): Promise<Scores> => {
  const formattedParams = {
    ...params,
    city_ids: params.city_ids.join(","),
  };

  const { data } = await axiosInstance.get<Scores>("/lat_lon/get_average_filtered_scores/", {
    params: formattedParams,
  });

  return data;
};
