import { AsyncState } from "../../models/AsyncState";
import { Facility } from "../../models/Facility";

export interface DemandFacilitiesState extends AsyncState {
  isExpanded: boolean;
  facilities: Facility[];
}

export const initialDemandFacilitiesState: DemandFacilitiesState = {
  isExpanded: false,
  facilities: [],
  isLoading: false,
  error: null,
};
