import { floor } from "lodash";
import { Bounds } from "../models/Bounds";
import { Coordinate } from "../models/Coordinate";
import { DEFAULT_COORDINATE_DECIMALS } from "../config/constants";

export const calculateLatLngBounds = (lat: number, lon: number, widthKm: number, heightKm: number): Bounds => {
  const earthRadiusKm = 6371;

  const latRadians = lat * (Math.PI / 180);

  const halfHeightKm = heightKm / 2;
  const halfWidthKm = widthKm / 2;

  const deltaLat = (halfHeightKm / earthRadiusKm) * (180 / Math.PI);
  const deltaLon = (halfWidthKm / (earthRadiusKm * Math.cos(latRadians))) * (180 / Math.PI);

  const north = lat + deltaLat;
  const south = lat - deltaLat;
  const east = lon + deltaLon;
  const west = lon - deltaLon;

  return { north, south, east, west };
};

export const truncateCoordinate = (coordinate: Coordinate, decimalPlaces: number = DEFAULT_COORDINATE_DECIMALS): Coordinate => {
  const factor = 10 ** decimalPlaces;

  return {
    latitude: Number(floor(coordinate.latitude * factor) / factor),
    longitude: Number(floor(coordinate.longitude * factor) / factor),
  };
};
