import { createSelector } from "reselect";
import { RootState } from "../storeTypes";

export const selectCitiesAverageScore = (state: RootState) => state.citiesAverageScore;

export const selectCityAveragesTabIsOpened = createSelector(selectCitiesAverageScore, (citiesAverageScore) => citiesAverageScore.isOpened);
export const selectAverageScores = createSelector(selectCitiesAverageScore, (citiesAverageScore) => citiesAverageScore.averageScores);
export const selectAverageScoresLoading = createSelector(selectCitiesAverageScore, (averageScore) => averageScore.isLoading);
export const selectAverageScoresError = createSelector(selectCitiesAverageScore, (averageScore) => averageScore.error);

// Computed selectors
export const selectRoundedAverageScore = createSelector(selectAverageScores, (averageScores) => {
  return (
    averageScores != null && {
      accessibility: Number(averageScores?.accessibility.toFixed(2)),
      demand: Number(averageScores?.demand.toFixed(2)),
      supply: Number(averageScores?.supply.toFixed(2)),
    }
  );
});
