import { createSelector } from "reselect";
import { RootState } from "../storeTypes";

export const selectAuth = (state: RootState) => state.auth;

export const selectIsAuthenticated = createSelector(selectAuth, (auth) => auth.isAuthenticated);
export const selectAccessToken = createSelector(selectAuth, (auth) => auth.accessToken);
export const selectRefreshToken = createSelector(selectAuth, (auth) => auth.refreshToken);
export const selectAuthLoading = createSelector(selectAuth, (auth) => auth.isLoading);
export const selectAuthError = createSelector(selectAuth, (auth) => auth.error);
