import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialCustomBusinessState } from "./customBusinessState";
import { CustomBusiness } from "../../models/CustomBusiness";
import { ErrorResponse } from "../../utils/errorHandler";

const customBusinessSlice = createSlice({
  name: "customBusiness",
  initialState: initialCustomBusinessState,
  reducers: {
    setSelectedCustomBusiness: (state, action: PayloadAction<CustomBusiness>) => {
      state.selectedCustomBusiness = action.payload;
    },
    resetSelectedCustomBusiness: (state) => {
      state.selectedCustomBusiness = null;
    },
    fetchCustomBusinesses: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCustomBusinessesSuccess: (state, action: PayloadAction<CustomBusiness[]>) => {
      state.customBusinesses = action.payload;
      state.isLoading = false;
    },
    fetchCustomBusinessesFailure: (state, action: PayloadAction<ErrorResponse>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetCustomBusiness: (state) => {
      state.customBusinesses = [];
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const {
  setSelectedCustomBusiness,
  resetSelectedCustomBusiness,
  fetchCustomBusinesses,
  fetchCustomBusinessesSuccess,
  fetchCustomBusinessesFailure,
  resetCustomBusiness,
} = customBusinessSlice.actions;

export const customBusinessReducer = customBusinessSlice.reducer;
