import { WithId } from "./arrayUtils";

export const arrayToIdObject = <T extends WithId>(objects: T[]): Record<number, T> =>
  objects.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {} as Record<number, T>);

export const objectsToArray = <T>(objects: Record<number, T>): T[] => Object.values(objects);

export const fromIdsArray = <T extends WithId>(ids: number[], objects: Record<number, T>): T[] => ids.map((id) => objects[id]);

export const toIdsArray = <T extends WithId>(objects: T[]): number[] => objects.map((obj) => obj.id);
