import { combineReducers } from "@reduxjs/toolkit";
import { countryReducer } from "./country/countrySlice";
import { regionReducer } from "./region/regionSlice";
import { businessTypeReducer } from "./businessType/businessTypeSlice";
import { scoreTypeReducer } from "./scoreType/scoreTypeSlice";
import { cityReducer } from "./city/citySlice";
import { scoreReducer } from "./score/scoreSlice";
import { heatMapReducer } from "./heatMap/heatMapSlice";
import { citiesAverageScoreReducer } from "./citiesAverageScore/citiesAverageScoreSlice";
import { latLonReducer } from "./latLon/latLonSlice";
import { customBusinessUploadedFilesReducer } from "./customBusinessUploadedFiles/customBusinessUploadedFilesSlice";
import { customBusinessReducer } from "./customBusiness/customBusinessSlice";
import { authReducer, logout } from "./auth/authSlice";
import { customBusinessValueReducer } from "./customBusinessValue/customBusinessValueSlice";
import { accessibilityFacilitiesReducer } from "./accessibilityFacilities/accessibilityFacilitiesSlice";
import { supplyFacilitiesReducer } from "./supplyFacilities/supplyFacilitiesSlice";
import { demandFacilitiesReducer } from "./demandFacilities/demandFacilitiesSlice";
import { googleMapReducer } from "./googleMap/googleMapSlice";
import { customBusinessUploadReducer } from "./customBusinessUpload/customBusinessUploadSlice";

const appReducer = combineReducers({
  auth: authReducer,
  country: countryReducer,
  city: cityReducer,
  region: regionReducer,
  businessType: businessTypeReducer,
  scoreType: scoreTypeReducer,
  score: scoreReducer,
  citiesAverageScore: citiesAverageScoreReducer,
  heatMap: heatMapReducer,
  latLon: latLonReducer,
  customBusinessUpload: customBusinessUploadReducer,
  customBusinessUploadedFiles: customBusinessUploadedFilesReducer,
  customBusiness: customBusinessReducer,
  customBusinessValue: customBusinessValueReducer,
  demandFacilities: demandFacilitiesReducer,
  supplyFacilities: supplyFacilitiesReducer,
  accessibilityFacilities: accessibilityFacilitiesReducer,
  googleMap: googleMapReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === logout.type) {
    state = undefined;
  }

  return appReducer(state, action);
};
