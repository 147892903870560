import axios from "axios";

export interface ErrorResponse {
  statusCode?: number;
  message: string;
}

export const errorHandler = (error: unknown): ErrorResponse => {
  if (axios.isAxiosError(error) && error.response) {
    return {
      statusCode: error.response.status,
      message:
        error.response.data?.error || "An error occurred with the request.",
    };
  } else if (axios.isAxiosError(error) && error.request) {
    return {
      message: "Network error: No response from the server.",
    };
  } else {
    return {
      message: "An unexpected error occurred.",
    };
  }
};
