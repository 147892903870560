import { createSelector } from "reselect";
import { RootState } from "../storeTypes";
import { calculateLatLngBounds } from "../../utils/coordinatesUtils";
import { selectColorForValue } from "../../utils/colorUtils";
import { selectHeatMapColorHashs } from "../heatMap/heatMapSelectors";
import { selectSelectedCountry } from "../country/countrySelectors";

export const selectScore = (state: RootState) => state.score;

export const selectScores = createSelector(selectScore, (score) => score.scores);
export const selectScoresLoading = createSelector(selectScore, (score) => score.isLoading);
export const selectScoresError = createSelector(selectScore, (score) => score.error);

export const selectSelectedScore = createSelector(selectScore, (score) => score.selectedScore);

// Computed selectors
export const selectSelectedScoreId = createSelector(selectSelectedScore, (selectedScore) => selectedScore?.id);
export const selectScoresWithSquareBounds = createSelector(
  [selectScores, selectHeatMapColorHashs, selectSelectedCountry],
  (scores, heatMapColorHashs, selectedCountry) => {
    if (!selectedCountry) return [];

    return scores.map((score) => ({
      score: score,
      color: selectColorForValue({
        numericValue: score.score_value,
        colorPalette: heatMapColorHashs,
        minNumericRange: 0,
        maxNumericRange: 100,
      }),
      bounds: calculateLatLngBounds(
        score.coordinate.latitude,
        score.coordinate.longitude,
        selectedCountry.rectangle.width_km,
        selectedCountry.rectangle.height_km
      ),
    }));
  }
);
