import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { getScoreTypes, ScoreType } from "../../api/scoreTypeApi";
import { fetchScoreTypes, fetchScoreTypesFailure, fetchScoreTypesSuccess } from "./scoreTypeSlice";

function* fetchScoreType(): Generator<any, void, ScoreType[]> {
  try {
    const scoreTypes = yield call(getScoreTypes);
    yield put(fetchScoreTypesSuccess(scoreTypes));
  } catch (error) {
    yield put(fetchScoreTypesFailure(error));
  }
}

export function* scoreTypeSaga() {
  yield takeLatest(fetchScoreTypes.type, fetchScoreType);
}

export function* scoreTypeSagas() {
  yield all([fork(scoreTypeSaga)]);
}
