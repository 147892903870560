import { RegionSummary } from "../../api/regionApi";
import { AsyncState } from "../../models/AsyncState";

export interface RegionState extends AsyncState {
  selectedRegions: RegionSummary[];
  regions: RegionSummary[];
}

export const regionInitialState: RegionState = {
  selectedRegions: [],
  regions: [],
  isLoading: false,
  error: null,
};
