import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchAccessibilityFacilities,
  fetchAccessibilityFacilitiesFailure,
  fetchAccessibilityFacilitiesSuccess,
  resetAccessibilityFacilities,
  resetIsExpandedAccessibility,
  setIsExpandedAccessibility,
} from "./accessibilityFacilitiesSlice";
import { selectAccessibilityFacilitiesIsExpanded } from "./accessibilityFacilitiesSelectors";
import { BusinessType } from "../../models/BusinessType";
import { selectSelectedBusinessType } from "../businessType/businessTypeSelectors";
import { ScoreType } from "../../api/scoreTypeApi";
import { selectScoreTypes } from "../scoreType/scoreTypeSelectors";
import { Facility } from "../../models/Facility";
import { getFacilities, GetFacilitiesParams } from "../../api/facilityApi";
import { selectSelectedScore } from "../score/scoreSelectors";
import { LatLonWithSpecificScore } from "../../api/scoreApi";
import { ErrorResponse } from "../../utils/errorHandler";
import { setSelectedScore } from "../score/scoreSlice";

// Watchers
function* watchSetIsExpandedAccessibility() {
  yield takeLatest([setIsExpandedAccessibility.type, setSelectedScore.type], fetchAccessibilityFacilitiesSaga);
}

function* watchResetAccessibility() {
  yield takeLatest(resetIsExpandedAccessibility.type, resetAccessibilityFacilitiesSaga);
}

function* watchFetchAccessibilityFacilities() {
  yield takeLatest(fetchAccessibilityFacilities.type, handleFetchAccessibilityFacilities);
}

// Sagas
function* fetchAccessibilityFacilitiesSaga() {
  const isExpanded: boolean = yield select(selectAccessibilityFacilitiesIsExpanded);
  const selectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
  const scoreTypes: ScoreType[] = yield select(selectScoreTypes);
  const selectedScore: LatLonWithSpecificScore | null = yield select(selectSelectedScore);
  const accessibilityScoreType: ScoreType | undefined = scoreTypes.find((st) => st.name === "Accessibility");

  if (!isExpanded || !selectedBusinessType || !scoreTypes || !selectedScore || !accessibilityScoreType) return;

  yield put(fetchAccessibilityFacilities());
}

function* resetAccessibilityFacilitiesSaga() {
  yield put(resetAccessibilityFacilities());
}

function* handleFetchAccessibilityFacilities() {
  try {
    const selectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
    const scoreTypes: ScoreType[] = yield select(selectScoreTypes);
    const selectedScore: LatLonWithSpecificScore | null = yield select(selectSelectedScore);
    const accessibilityScoreType: ScoreType | undefined = scoreTypes.find((st) => st.name === "Accessibility");

    if (!selectedBusinessType || !selectedScore || !accessibilityScoreType) {
      yield put(
        fetchAccessibilityFacilitiesFailure({
          message: "Please ensure that the business type and score are selected and Score types include accessibility.",
        })
      );
      return;
    }

    const params: GetFacilitiesParams = {
      business_type_id: selectedBusinessType.id,
      lat_lon_id: selectedScore.id,
      score_type_id: accessibilityScoreType.id,
    };

    const facilities: Facility[] = yield call(getFacilities, params);
    yield put(fetchAccessibilityFacilitiesSuccess(facilities));
  } catch (error) {
    yield put(fetchAccessibilityFacilitiesFailure(error as ErrorResponse));
  }
}

// Root saga
export function* accessibilityFacilitiesSaga() {
  yield all([fork(watchSetIsExpandedAccessibility), fork(watchResetAccessibility), fork(watchFetchAccessibilityFacilities)]);
}
