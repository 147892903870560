import { RefreshTokenResponse } from "./../../api/authApi";
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { Credentials } from "../../models/Credentials";
import { PayloadAction } from "@reduxjs/toolkit";
import { TokenResponse } from "../../api/authApi";
import { ErrorResponse } from "../../utils/errorHandler";
import { selectAccessToken, selectRefreshToken } from "./authSelectors";
import * as authApi from "../../api/authApi";
import {
  logout,
  refreshTokenSuccess,
  tokenFailure,
  tokenRequest,
  tokenSuccess,
} from "./authSlice";
import { jwtDecode } from "jwt-decode";

function* watchTokenRequest() {
  yield takeLatest(tokenRequest.type, tokenSaga);
}

function* tokenSaga(action: PayloadAction<Credentials>) {
  try {
    const response: TokenResponse = yield call(
      authApi.getToken,
      action.payload
    );
    yield put(
      tokenSuccess({
        access: response.access,
        refresh: response.refresh,
      })
    );
  } catch (error: any) {
    yield put(tokenFailure(error as ErrorResponse));
  }
}

function* watchTokenExpiration() {
  while (true) {
    const accessToken: string | null = yield select(selectAccessToken);
    const oneMinuteMs = 60000;
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const expTimeMs = decodedToken.exp ? decodedToken.exp * 1000 : 0;
      const currentTime = Date.now();
      const delayTime = expTimeMs - currentTime - oneMinuteMs;

      if (delayTime > 0) {
        yield delay(delayTime);
        yield call(refreshTokenSaga);
      } else {
        yield call(refreshTokenSaga);
      }
    } else {
      yield delay(oneMinuteMs);
    }
  }
}

function* refreshTokenSaga() {
  try {
    const refreshToken: string | null = yield select(selectRefreshToken);
    if (refreshToken) {
      const response: RefreshTokenResponse = yield call(
        authApi.refreshToken,
        refreshToken
      );
      yield put(refreshTokenSuccess({ accessToken: response.access }));
    } else {
      yield put(logout());
    }
  } catch (error) {
    console.error(error);
    yield put(logout());
  }
}

export default function* authSaga() {
  yield all([fork(watchTokenRequest), fork(watchTokenExpiration)]);
}
