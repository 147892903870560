import { createSelector } from "reselect";
import { RootState } from "../storeTypes";

export const selectDemandFacilitiesState = (state: RootState) => state.demandFacilities;

export const selectDemandFacilitiesIsExpanded = createSelector(selectDemandFacilitiesState, (demandFacilities) => demandFacilities.isExpanded);

export const selectDemandFacilities = createSelector(selectDemandFacilitiesState, (demandFacilities) => demandFacilities.facilities);

export const selectDemandFacilitiesLoading = createSelector(selectDemandFacilitiesState, (demandFacilities) => demandFacilities.isLoading);

export const selectDemandFacilitiesError = createSelector(selectDemandFacilitiesState, (demandFacilities) => demandFacilities.error);
