import { Credentials } from "../../models/Credentials";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialAuthState } from "./authState";
import { ErrorResponse } from "../../utils/errorHandler";
import { TokenResponse } from "../../api/authApi";

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    tokenRequest(state, _action: PayloadAction<Credentials>) {
      state.isLoading = true;
      state.error = null;
    },
    tokenSuccess(state, action: PayloadAction<TokenResponse>) {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      state.isAuthenticated = true;
      state.isLoading = false;
    },
    tokenFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
    },
    refreshTokenSuccess(state, action: PayloadAction<{ accessToken: string }>) {
      state.accessToken = action.payload.accessToken;
    },
  },
});

export const { tokenRequest, tokenSuccess, tokenFailure, logout, refreshTokenSuccess } = authSlice.actions;

export const authReducer = authSlice.reducer;
