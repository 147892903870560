import { Flex, Spin } from "antd";

const LoadingSpinner: React.FC = () => (
  // TODO: remove height 100vh
  <Flex justify="center" align="center" style={{ height: "100vh" }}>
    <Spin size="large" />
  </Flex>
);

export default LoadingSpinner;
