import { createSelector } from "reselect";
import { RootState } from "../storeTypes";

export const selectAccessibilityFacilitiesState = (state: RootState) => state.accessibilityFacilities;

export const selectAccessibilityFacilitiesIsExpanded = createSelector(
  selectAccessibilityFacilitiesState,
  (accessibilityFacilities) => accessibilityFacilities.isExpanded
);
export const selectAccessibilityFacilities = createSelector(
  selectAccessibilityFacilitiesState,
  (accessibilityFacilities) => accessibilityFacilities.facilities
);
export const selectAccessibilityFacilitiesLoading = createSelector(
  selectAccessibilityFacilitiesState,
  (accessibilityFacilities) => accessibilityFacilities.isLoading
);
export const selectAccessibilityFacilitiesError = createSelector(
  selectAccessibilityFacilitiesState,
  (accessibilityFacilities) => accessibilityFacilities.error
);
