import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CitySummary } from "../../api/cityApi";
import { cityInitialState } from "./cityState";
import { ErrorResponse } from "../../utils/errorHandler";

const citySlice = createSlice({
  name: "city",
  initialState: cityInitialState,
  reducers: {
    setSelectedCities(state, action: PayloadAction<CitySummary[]>) {
      state.selectedCities = action.payload;
    },
    fetchCities: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCitiesSuccess: (state, action: PayloadAction<CitySummary[]>) => {
      state.isLoading = false;
      state.cities = action.payload;
    },
    fetchCitiesFailure: (state, action: PayloadAction<ErrorResponse>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { setSelectedCities, fetchCities, fetchCitiesSuccess, fetchCitiesFailure } = citySlice.actions;

export const cityReducer = citySlice.reducer;
