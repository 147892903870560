import { AsyncState } from "../../models/AsyncState";
import { Scores } from "../../models/Scores";

export interface AverageScoresState extends AsyncState {
  isOpened: boolean;
  averageScores: Scores | null;
}
export const averageScoresAsyncStateInitialState: AverageScoresState = {
  isOpened: false,
  averageScores: null,
  isLoading: false,
  error: null,
};
