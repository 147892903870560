import axiosInstance from "./axios";

export interface CustomBusinessUploadParams {
  country_id: number;
  business_type_id: number;
  file: File;
}

export interface CustomBusinessUploadResponse {
  message: string;
}

export const uploadCustomBusiness = async (params: CustomBusinessUploadParams): Promise<CustomBusinessUploadResponse> => {
  const formData = new FormData();
  formData.append("country_id", params.country_id.toString());
  formData.append("business_type_id", params.business_type_id.toString());
  formData.append("file", params.file);

  const { data } = await axiosInstance.post<CustomBusinessUploadResponse>("/custom_business_upload/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

export const deleteCustomBusiness = async (id: number): Promise<void> => {
  await axiosInstance.delete(`/custom_business_upload/${id}/`);
};
