import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { resetSelectedFileProperty, setSelectedFileProperty } from "../customBusinessUploadedFiles/customBusinessUploadedFilesSlice";
import {
  fetchCustomBusinessValues,
  fetchCustomBusinessValuesFailure,
  fetchCustomBusinessValuesSuccess,
  resetCustomBusinessValues,
} from "./customBusinessValueSlice";
import {
  selectSelectedCustomBusinessUploadedFile,
  selectSelectedCustomBusinessUploadedFileProperty,
} from "../customBusinessUploadedFiles/customBusinessUploadedFilesSelectors";
import { CustomBusinessUploadedFile } from "../../models/CustomBusinessUploadedFile";
import { CustomBusinessProperty } from "../../models/CustomBusinessProperty";
import { getCustomBusinessValues, GetCustomBusinessValuesParams } from "../../api/customBusinessValueApi";
import { CustomBusinessValue } from "../../models/CustomBusinessValue";
import { ErrorResponse } from "../../utils/errorHandler";

function* watchResetSelectedFileProperty() {
  yield takeLatest(resetSelectedFileProperty.type, resetValuesSaga);
}

function* resetValuesSaga() {
  yield put(resetCustomBusinessValues());
}

function* watchSetSelectedFileProperty() {
  yield takeLatest(setSelectedFileProperty.type, fetchValuesSaga);
}

function* fetchValuesSaga() {
  yield put(fetchCustomBusinessValues());
}

function* watchFetchCustomBusinessValues() {
  yield takeLatest(fetchCustomBusinessValues.type, fetchCustomBusinessValuesSaga);
}

function* fetchCustomBusinessValuesSaga() {
  const selectedFile: CustomBusinessUploadedFile | null = yield select(selectSelectedCustomBusinessUploadedFile);
  const selectedProperty: CustomBusinessProperty | null = yield select(selectSelectedCustomBusinessUploadedFileProperty);

  if (!selectedFile || !selectedProperty) {
    yield put(fetchCustomBusinessValuesFailure({ message: "You must select a file and a property" }));
    return;
  }

  try {
    const params: GetCustomBusinessValuesParams = {
      custom_business_uploaded_file_id: selectedFile.id,
      property_id: selectedProperty.id,
    };
    const customBusinessValues: CustomBusinessValue<string>[] = yield call(getCustomBusinessValues, params);
    yield put(fetchCustomBusinessValuesSuccess(customBusinessValues));
  } catch (error) {
    yield put(fetchCustomBusinessValuesFailure(error as ErrorResponse));
  }
}

export function* customBusinessValueSaga() {
  yield all([fork(watchResetSelectedFileProperty), fork(watchSetSelectedFileProperty), fork(watchFetchCustomBusinessValues)]);
}
