import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetchBusinessTypesFailure, fetchBusinessTypesSuccess } from "./businessTypeSlice";
import { ErrorResponse } from "../../utils/errorHandler";
import { fetchCountries } from "../country/countrySlice";
import { BusinessType } from "../../models/BusinessType";
import { getBusinessTypes } from "../../api/businessTypeApi";

function* fetchBusinessTypesSaga(): Generator<any, void, BusinessType[]> {
  try {
    const businessTypes = yield call(getBusinessTypes);
    yield put(fetchBusinessTypesSuccess(businessTypes));
  } catch (error) {
    yield put(fetchBusinessTypesFailure(error as ErrorResponse));
  }
}

function* watchFetchBusinessType() {
  yield takeLatest(fetchCountries.type, fetchBusinessTypesSaga);
}

export function* businessTypeSaga() {
  yield all([watchFetchBusinessType()]);
}
