import { Bounds } from "../../models/Bounds";
import { Coordinate } from "../../models/Coordinate";
import { Dimensions } from "../../models/Dimensions";

export interface GoogleMapState {
  center: Coordinate | null;
  zoom: number;
  bounds: Bounds | null;
  dimensions: Dimensions | null;
}

export const googleMapInitialState: GoogleMapState = {
  center: null,
  zoom: 5,
  bounds: null,
  dimensions: null,
};
