import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialSupplyFacilitiesState } from "./supplyFacilitiesState";
import { ErrorResponse } from "../../utils/errorHandler";
import { Facility } from "../../models/Facility";

const supplyFacilitiesSlice = createSlice({
  name: "supplyFacilities",
  initialState: initialSupplyFacilitiesState,
  reducers: {
    setIsExpandedSupply(state) {
      state.isExpanded = true;
    },
    resetIsExpandedSupply(state) {
      state.isExpanded = false;
    },
    fetchSupplyFacilities(state) {
      state.error = null;
      state.isLoading = true;
    },
    fetchSupplyFacilitiesSuccess(state, action: PayloadAction<Facility[]>) {
      state.facilities = action.payload;
      state.isLoading = false;
    },
    fetchSupplyFacilitiesFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetSupplyFacilities(state) {
      state.error = initialSupplyFacilitiesState.error;
      state.isLoading = initialSupplyFacilitiesState.isLoading;
      state.facilities = initialSupplyFacilitiesState.facilities;
    },
  },
});

export const {
  setIsExpandedSupply,
  resetIsExpandedSupply,
  fetchSupplyFacilities,
  fetchSupplyFacilitiesSuccess,
  fetchSupplyFacilitiesFailure,
  resetSupplyFacilities,
} = supplyFacilitiesSlice.actions;

export const supplyFacilitiesReducer = supplyFacilitiesSlice.reducer;
