import { ScoreType } from "../../api/scoreTypeApi";
import { AsyncState } from "../../models/AsyncState";

export interface ScoreTypeState extends AsyncState {
  selectedScoreType: ScoreType | null;
  scoreTypes: ScoreType[];
}

export const scoreTypeInitialState: ScoreTypeState = {
  selectedScoreType: null,
  scoreTypes: [],
  isLoading: false,
  error: null,
};
