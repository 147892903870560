export type WithId = {
  id: number;
};

export const removeItemById = <T extends WithId>(items: Array<T>, id: number): Array<T> => {
  const indexToRemove = items.findIndex((item) => item.id === id);
  if (indexToRemove === -1) return items;

  const newItems = [...items];
  newItems.splice(indexToRemove, 1);
  return newItems;
};

export const updateById = <T extends WithId>(items: T[], id: number, newProperties: Partial<T>): T[] => {
  return items.map((item) => (item.id === id ? { ...item, ...newProperties } : item));
};

export const findById = <T extends WithId>(items: T[], id: number): T | undefined => {
  return items.find((item) => item.id === id);
};

export const areAllItemsSelected = <T extends WithId>(items: Array<T>, selectedItems: Array<T>): boolean => {
  return items.length === selectedItems.length;
};
