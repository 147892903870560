import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      <h1>404 - Page Not Found</h1>
      <Button type="primary" onClick={() => navigate("/")}>
        Go Home
      </Button>
    </div>
  );
};

export default NotFoundPage;
