import { AsyncState } from "../../models/AsyncState";
import { BusinessType } from "../../models/BusinessType";

export interface BusinessTypeState extends AsyncState {
  selectedBusinessType: BusinessType | null;
  businessTypes: BusinessType[];
}

export const businessTypeInitialState: BusinessTypeState = {
  selectedBusinessType: null,
  businessTypes: [],
  isLoading: false,
  error: null,
};
