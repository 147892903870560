import { createSlice } from "@reduxjs/toolkit";
import { scoreTypeInitialState } from "./scoreTypeState";

const scoreTypeSlice = createSlice({
  name: "scoreType",
  initialState: scoreTypeInitialState,
  reducers: {
    setSelectedScoreType(state, action) {
      state.selectedScoreType = action.payload;
    },
    fetchScoreTypes: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchScoreTypesSuccess: (state, action) => {
      state.isLoading = false;
      state.scoreTypes = action.payload;
    },
    fetchScoreTypesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { setSelectedScoreType, fetchScoreTypes, fetchScoreTypesSuccess, fetchScoreTypesFailure } = scoreTypeSlice.actions;

export const scoreTypeReducer = scoreTypeSlice.reducer;
