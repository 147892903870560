import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthLoading, selectIsAuthenticated } from "../../redux/auth/authSelectors";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const loading = useSelector(selectAuthLoading);

  if (loading) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" replace />;
  }

  return <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>;
};

export default ProtectedRoute;
