import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchSupplyFacilities,
  fetchSupplyFacilitiesFailure,
  fetchSupplyFacilitiesSuccess,
  resetSupplyFacilities,
  resetIsExpandedSupply,
  setIsExpandedSupply,
} from "./supplyFacilitiesSlice";
import { selectSupplyFacilitiesIsExpanded } from "./supplyFacilitiesSelectors";
import { BusinessType } from "../../models/BusinessType";
import { selectSelectedBusinessType } from "../businessType/businessTypeSelectors";
import { ScoreType } from "../../api/scoreTypeApi";
import { selectScoreTypes } from "../scoreType/scoreTypeSelectors";
import { Facility } from "../../models/Facility";
import { getFacilities, GetFacilitiesParams } from "../../api/facilityApi";
import { selectSelectedScore } from "../score/scoreSelectors";
import { LatLonWithSpecificScore } from "../../api/scoreApi";
import { ErrorResponse } from "../../utils/errorHandler";
import { setSelectedScore } from "../score/scoreSlice";

// Watchers
function* watchSetIsExpandedSupply() {
  yield takeLatest([setIsExpandedSupply.type, setSelectedScore.type], fetchSupplyFacilitiesSaga);
}

function* watchResetSupply() {
  yield takeLatest(resetIsExpandedSupply.type, resetSupplyFacilitiesSaga);
}

function* watchFetchSupplyFacilities() {
  yield takeLatest(fetchSupplyFacilities.type, handleFetchSupplyFacilities);
}

// Sagas
function* fetchSupplyFacilitiesSaga() {
  const isExpanded: boolean = yield select(selectSupplyFacilitiesIsExpanded);
  const selectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
  const scoreTypes: ScoreType[] = yield select(selectScoreTypes);
  const selectedScore: LatLonWithSpecificScore | null = yield select(selectSelectedScore);
  const supplyScoreType: ScoreType | undefined = scoreTypes.find((st) => st.name === "Supply");

  if (!isExpanded || !selectedBusinessType || !scoreTypes || !selectedScore || !supplyScoreType) return;

  yield put(fetchSupplyFacilities());
}

function* resetSupplyFacilitiesSaga() {
  yield put(resetSupplyFacilities());
}

function* handleFetchSupplyFacilities() {
  try {
    const selectedBusinessType: BusinessType | null = yield select(selectSelectedBusinessType);
    const scoreTypes: ScoreType[] = yield select(selectScoreTypes);
    const selectedScore: LatLonWithSpecificScore | null = yield select(selectSelectedScore);
    const supplyScoreType: ScoreType | undefined = scoreTypes.find((st) => st.name === "Supply");

    if (!selectedBusinessType || !selectedScore || !supplyScoreType) {
      yield put(
        fetchSupplyFacilitiesFailure({
          message: "Please ensure that the business type and score are selected and Score types include supply.",
        })
      );
      return;
    }

    const params: GetFacilitiesParams = {
      business_type_id: selectedBusinessType.id,
      lat_lon_id: selectedScore.id,
      score_type_id: supplyScoreType.id,
    };

    const facilities: Facility[] = yield call(getFacilities, params);
    yield put(fetchSupplyFacilitiesSuccess(facilities));
  } catch (error) {
    yield put(fetchSupplyFacilitiesFailure(error as ErrorResponse));
  }
}

// Root saga
export function* supplyFacilitiesSaga() {
  yield all([fork(watchSetIsExpandedSupply), fork(watchResetSupply), fork(watchFetchSupplyFacilities)]);
}
