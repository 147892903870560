import { CancelTokenSource } from "axios";
import { ErrorResponse } from "../utils/errorHandler";
import { fetchStream } from "./axios";
import { Coordinate } from "../models/Coordinate";

export type LatLonWithSpecificScore = {
  id: number;
  coordinate: Coordinate;
  score_value: number;
};

export type GetScoresParams = {
  city_ids: number[];
  zoom_id: number;
  business_type_id: number;
  score_type_id: number;
};

export const getScoresStream = async ({
  params,
  onData,
  onError,
  cancelToken,
}: {
  params: GetScoresParams;
  onData: (data: Array<LatLonWithSpecificScore>) => void;
  onError: (error: ErrorResponse) => void;
  cancelToken?: CancelTokenSource;
}) => {
  const formattedParams = {
    ...params,
    city_ids: params.city_ids.join(","),
  };

  await fetchStream<LatLonWithSpecificScore>("/lat_lon/get_filtered_scores/", {
    params: formattedParams,
    onData: (data: Array<LatLonWithSpecificScore>) => {
      if (cancelToken && cancelToken.token.reason) return; // Check for cancellation

      onData(data);
    },
    onError: (error: ErrorResponse) => {
      if (cancelToken && cancelToken.token.reason) return; // Check for cancellation

      onError(error);
    },
    cancelToken,
  });
};
