import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { businessTypeInitialState } from "./businessTypeState";

import { ErrorResponse } from "../../utils/errorHandler";
import { BusinessType } from "../../models/BusinessType";

const businessTypeSlice = createSlice({
  name: "businessType",
  initialState: businessTypeInitialState,
  reducers: {
    setSelectedBusinessType(state, action: PayloadAction<BusinessType>) {
      state.selectedBusinessType = action.payload;
    },
    fetchBusinessTypes: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchBusinessTypesSuccess: (state, action: PayloadAction<BusinessType[]>) => {
      state.isLoading = false;
      state.businessTypes = action.payload;
    },
    fetchBusinessTypesFailure: (state, action: PayloadAction<ErrorResponse>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { setSelectedBusinessType, fetchBusinessTypes, fetchBusinessTypesSuccess, fetchBusinessTypesFailure } = businessTypeSlice.actions;

export const businessTypeReducer = businessTypeSlice.reducer;
