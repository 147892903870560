import { RouterProvider } from "react-router-dom";
import "./App.scss";
import { router } from "./routes/Routes";
import { Suspense } from "react";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";

function App() {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
