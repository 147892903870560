import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { scoreInitialState } from "./scoreState";
import { LatLonWithSpecificScore } from "../../api/scoreApi";
import { ErrorResponse } from "../../utils/errorHandler";

const scoreSlice = createSlice({
  name: "score",
  initialState: scoreInitialState,
  reducers: {
    setSelectedScore: (state, { payload }: PayloadAction<LatLonWithSpecificScore>) => {
      state.selectedScore = payload;
    },
    resetSelectedScore: (state) => {
      state.selectedScore = null;
    },
    appendScores: (state, { payload }: PayloadAction<LatLonWithSpecificScore[]>) => {
      state.scores = [...state.scores, ...payload];
    },
    fetchScores: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    // TODO: use this or remove loading state
    fetchScoresSuccess: (state) => {
      state.isLoading = false;
    },
    fetchScoresFailure: (state, { payload }: PayloadAction<ErrorResponse>) => {
      state.isLoading = false;
      state.error = payload;
    },
    resetScores: (state) => {
      state.selectedScore = null;
      state.scores = [];
      state.isLoading = false;
    },
  },
});

export const { setSelectedScore, resetSelectedScore, appendScores, fetchScores, fetchScoresSuccess, fetchScoresFailure, resetScores } = scoreSlice.actions;

export const scoreReducer = scoreSlice.reducer;
