import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { countryInitialState } from "./countryState";
import { ErrorResponse } from "../../utils/errorHandler";
import { CountrySummary } from "../../models/CountrySummary";

const countrySlice = createSlice({
  name: "country",
  initialState: countryInitialState,
  reducers: {
    setSelectedCountry(state, action) {
      state.selectedCountry = action.payload;
    },
    fetchCountries: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCountriesSuccess: (state, action: PayloadAction<CountrySummary[]>) => {
      state.isLoading = false;
      state.countries = action.payload;
    },
    fetchCountriesFailure: (state, action: PayloadAction<ErrorResponse | null>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { setSelectedCountry, fetchCountries, fetchCountriesSuccess, fetchCountriesFailure } = countrySlice.actions;

export const countryReducer = countrySlice.reducer;
